<template>
    <div class="btn" @click="loadMore" ref="btn">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        props: {
            step: 1,
            cards_length: 1
        },
        data () {
            return {
                
            }
        },
        methods: {
            async loadMore(){
                // let form_data = new FormData();
                // this.$refs.btn.style.pointerEvents = 'none';
                // form_data.append('action', 'loadMore');
                // form_data.append('offset', this.step);
                // form_data.append('offset', this.cards_length);

                // let xhr = new XMLHttpRequest();
                // xhr.open('POST', myajax.url);
                // xhr.send(form_data);
                // xhr.onload = function () {
                //     if (xhr.status != 200) {
                //     console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
                //     } else {
                //     let result = JSON.parse(xhr.response);

                //     cards.push(result.content);

                //     if (result.is_more == true) {
                //         this.$refs.btn.style.display = 'none';
                //     }
                //     this.$refs.btn.style.pointerEvents = 'all';
                //     }
                // }
                const result = {title: 'Четвертая карточка', content: 'Четвертая Четвертая Четвертая Четвертая Четвертая Четвертая'}
                this.$emit('loadMore', result);
            }
        }
    }
</script>
<style>
    /* .btn{
        border-radius: 55px;
        background: red;
        color: white;
        padding: 10px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        cursor: pointer;
    } */
</style>