<template>
  <div ref="list" class="drop-list">
    <DropItem
      ref="drop_item"
      @open="changeActiveItem"
      @close="closeActiveItem"
      v-for="(item, index) in tabs"
      :header="item.title"
      :salary="item.salary"
      :index="index"
      :is_active="active_item == index"
    >
      <div class="drop-element" v-html="item.text"></div>
      <Button
        class="drop-element-btn"
        v-if="with_btns"
        @click="btns_function(item.title)"
        color="white"
        >Оставить заявку</Button
      >
    </DropItem>
  </div>
</template>
<script>
import DropItem from "@/components/base/DropItem.vue";
import Button from "@/components/UI/Button.vue";

export default {
  components: {
    DropItem,
    Button,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    with_btns: {
      type: Boolean,
      default: () => false,
    },
    btns_function: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      active_item: -1,
    };
  },
  methods: {
    changeActiveItem(index_item) {
      this.active_item = index_item;
      this.$refs.drop_item.forEach((item, index) => {
        if (index_item == index) {
          item.open();
        } else {
          item.close();
        }
      });
    },
    closeActiveItem(index_item) {
      this.active_item = -1;
      this.$refs.drop_item[index_item].close();
    },
  },
};
</script>
<style>
.drop-list {
  width: 100%;
}

.drop-element-btn {
  position: absolute;
  right: 25px;
  bottom: 25px;
  transition: 0.5s;
  opacity: 0;
  width: max-content;
}

.drop-item.active .drop-element-btn {
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .drop-list {
    grid-area: auto;
  }
}
</style>
