<template>
    <h3 v-html="title"></h3>
    <p>Заполните поля и с Вами свяжутся в течение суток!</p>
    <form class="popup_form">
        <div class="popup_form-inputs">
            <div class="popup_form-inputs-block">
                <input v-model="name" class="popup_form-item" type="text" placeholder="Имя"/>
                <span class="popup_form-error" :class="name_error != '' ? 'active' : ''">{{ name_error }}</span>
            </div>
            <div class="popup_form-inputs-block">
                <input v-model="phone" class="popup_form-item" type="tel" placeholder="+7 (999) 999 99 99" ref="phone"/>
                <span class="popup_form-error" :class="phone_error != '' ? 'active' : ''">{{ phone_error }}</span>
            </div>
            <div class="popup_form-inputs-block">
                <input v-model="email" class="popup_form-item" type="email" placeholder="Почта" />
                <span class="popup_form-error" :class="email_error != '' ? 'active' : ''">{{ email_error }}</span>
            </div>
            <div class="popup_form-inputs-block" v-if="is_vacancy">
                <input v-model="resume_link" class="popup_form-item" type="text" placeholder="Ссылка на резюме" />
                <span class="popup_form-error" :class="resume_link_error != '' ? 'active' : ''">{{ resume_link_error }}</span>
            </div>
        </div>
        <p class="popup_form-policy">
            Нажимая кнопку, вы соглашаетесь<br>
            <a href="/privacy-policy">с политикой конфеденциальности</a>
        </p>
        <Button color="orange" @click="send">Отправить</Button>
    </form>
</template>
<script>
import { validateUrl, validateEmail, replaceNumberForPaste, replaceToLatters } from '@/assets/js/helper.js';
import orderApi from '@/assets/js/api/order.js';
import Button from "@/components/UI/Button.vue";
import { mapState } from "vuex";

export default {
    components: {
        Button,
    },
    data() {
        return {
            title:             'Заявка на проект',
            name_error:        '',
            phone_error:       '',
            email_error:       '',
            name:              '',
            phone:             '',
            email:             '',
            is_vacancy:        false,
            resume_link:       '',
            resume_link_error: '',
            is_partner:        false,
        }
    },
    methods: {
        async send() {

            this.validateFiled('name', this.name);
            this.validateFiled('phone', this.phone);
            this.validateFiled('email', this.email);
            this.validateFiled('resume_link', this.resume_link);
            
            const is_empty      = (this.name == '' || this.phone == '' || this.email == '') ? true : false,
                  has_error     = (this.name_error || this.phone_error || this.email_error) ? true : false,
                  vacancy_valid = ((this.resume_link == '') || (this.resume_link != '' && this.resume_link_error == '')) ? true : false;

            if ( !is_empty && !has_error && vacancy_valid) {

                const page = this.$route.path.replace('/', '');

                const data = {
                    request_page: page != '' ? page : 'home',
                    type:         'Заявка на проект',
                    name:         this.name,
                    phone:        this.phone,
                    email:        this.email,
                };

                if (this.is_vacancy) {
                    data.type        = 'Заявка на вакансию';
                    data.vacancy     = this.vacancy;
                    data.resume_link = this.resume_link;
                }

                if (this.is_partner) {
                    data.type        = 'Заявка на партнёрство';
                }

                const result = await orderApi.create(data);

                if (result) {
                    this.$store.commit('resultSuccess', result.success)
                }
                this.$emit("send");
            }
        },
        validateFiled(field_name, value) {
            switch (field_name) {
                case 'name':
                    this.name_error  = '';

                    if (value == '') {
                        this.name_error = "Обязательное поле!";
                        return;
                    }

                    break;
            
                case 'phone':
                    this.phone_error = '';

                    if (value == '') {
                        this.phone_error = "Обязательное поле!";
                        return;
                    }

                    if (value.length < 18) {
                        this.phone_error = "Формат номера +7 (999) 999-99-99";
                        return;
                    }

                    break;

                case 'email':
                    this.email_error = '';

                    if (!validateEmail(value)) {
                        this.email_error = "Некорректный email!";
                        return;
                    }

                    break;
                
                case 'resume_link':
                    this.resume_link_error = '';

                    if (value != '' && !validateUrl(value)) {
                        this.resume_link_error = 'Некорректная ссылка!'; 
                    }

                    break;
            }
        }
    },
    emit: ['send'],
    watch: {
        name(new_value) {
            this.name = replaceToLatters(new_value, true);
            this.validateFiled('name', new_value);
        },
        phone(new_value) {
            this.phone = replaceNumberForPaste(new_value);
            this.validateFiled('phone', new_value);
        },
        email(new_value) {
            this.validateFiled('email', new_value);
        },
        resume_link(new_value) {
            this.validateFiled('resume_link', new_value);
        },
        popup() {
            this.is_vacancy = false;
            this.is_partner = false;

            if (this.$route.path == '/vacancy') {
                this.is_vacancy = true;
                this.title      = 'Заявка на вакансию';

                if (this.vacancy != '') {
                    this.title = 'Заявка на вакансию<br>"' + this.vacancy + '"';
                }
            }

            if (this.$route.path == '/partners') {
                this.is_partner = true;
                this.title      = 'Заявка на партнёрство';
            }
        }
    },
    computed: {
        ...mapState({
            vacancy: (state) => state.vacancy,
            popup: (state) => state.examplePopup,
        }),
    },
};
</script>
<style>
.popup_form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.popup_form-inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.popup-header h3 {
    color: var(--golden);
}

.popup_form-inputs-block {
    position: relative;
}

.popup_form-error.active {
    transition: 0.5s;
    opacity: 1;
}

input:is([type="email"], [type="text"], [type="tel"]).popup_form-item {
    color: var(--main-color);
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--golden);
    padding: 20px 0;
    width: 100%;
}

input:is([type="email"],
    [type="text"],
    [type="tel"]).popup_form-item:placeholder-shown,
input:is([type="email"],
    [type="text"],
    [type="tel"]).popup_form-item::placeholder {
    color: var(--grey);
}

.popup_form-error {
    color: var(--red);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    width: 100%;
    text-align: center;
}

.popup_form-policy {
    color: var(--grey);
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    align-self: center;
    text-align: center;
}

.popup_form-policy a {
    color: var(--grey);
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-decoration-line: underline;
    transition: 0.5s;
}

.popup_form-policy a:hover {
    color: var(--golden);
}

.popup_form .button-link {
    align-self: center;
}

@media screen and (max-width: 800px) {

    .popup_form-policy,
    .popup_form-policy a {
        font-size: 16px;
        line-height: 25px;
        text-align: justify;
    }
}
</style>