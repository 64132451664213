<template>
  <section>
    <div class="container">
      <div
        class="section-title"
        :class="
          $router.currentRoute.value.path.indexOf('serial_projects') > 0 ||
          $router.currentRoute.value.path.indexOf('individual_projects') > 0
            ? 'serial'
            : ''
        "
      >
        <slot name="title"></slot>
      </div>
      <slot></slot>
    </div>
  </section>
</template>
<script>
export default {
  name: "TitleWithColumns",
  props: {
    title: "",
  },
};
</script>
