<template>
    <div class="cities-list-container" :class="($store.state?.cityMenuOpen ? 'open' : '')">
        <div class="cities-list-close" @click="close">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9375 3.0625L3.0625 10.9375" stroke="#585858" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.9375 10.9375L3.0625 3.0625" stroke="#585858" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="cities-list-loading" :class="is_loading ? 'active' : ''" >
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <div class="cities-list-title">
            Выберите ваш город
        </div>
        <div class="cities-list">
            <div class="cities-list-item" v-for="city in site_info.cities" @click="changeCity(city.id)">
                {{ city.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import siteInfo from '@/assets/js/api/site-info.js';

export default {
    data() {
        return {
            cities_list: [],
            is_loading: false
        }
    },
    methods: {
        close() {
            this.$store.commit('cityMenuOpen', false);
        },
        async changeCity(id) {
            this.is_loading = true;
            const site_info = await siteInfo.get(id, true);
		    this.$store.commit('setSiteInfo', site_info);
            this.$store.commit('cityMenuOpen', false);
            this.is_loading = false;
        }
    },
    computed: {
        ...mapState({
            site_info: (state) => state.site_info,
        }),
    },
}
</script>

<style>
.cities-list-container {
    position: fixed;
    top: 5px;
    right: 20px;
    background: #FFF;
    box-shadow: 4px 5px 8px 5px rgba(88, 88, 88, 0.08);
    padding: 10px 25px;
    z-index: 999;
    width: 300px;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.cities-list-container.open {
    opacity: 1;
    pointer-events: all;
}

.cities-list-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
}

.cities-list-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
}

.cities-list-loading.active {
    pointer-events: all;
    opacity: 1;
}

.cities-list-title {
    color: var(--golden);
    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}

.cities-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cities-list-item {
    color: var(--grey);
    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    text-decoration-line: underline; 
    cursor: pointer;
}

@media screen and (max-width:1200px) {
    .cities-list-container {
        top: 70px;
        left: calc((100% - 300px) / 2);
    }
}

.cities-list-container .lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.cities-list-container .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid var(--golden);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--golden) transparent transparent transparent;
}

.cities-list-container .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.cities-list-container .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.cities-list-container .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
