<template>
  <header ref="header" :class="$store.state.is_scroll ? 'shadow' : ''">
    <div class="container header-container">
      <div class="w50">
        <div class="header-menu-btn" @click="openMenu()">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <a href="/" class="header-logo-link">
        <img class="header-logo" :src="$store.state.site_info?.info.header_logo ?? logo" alt="" />
      </a>
      <div class="w50 align-right">
        <div class="header-contacts">
          <div class="choos-city" @click="openCity">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 12C13.1935 12 14.3381 11.5259 15.182 10.682C16.0259 9.83807 16.5 8.69347 16.5 7.5C16.5 6.30653 16.0259 5.16193 15.182 4.31802C14.3381 3.47411 13.1935 3 12 3C10.8065 3 9.66193 3.47411 8.81802 4.31802C7.97411 5.16193 7.5 6.30653 7.5 7.5C7.5 8.69347 7.97411 9.83807 8.81802 10.682C9.66193 11.5259 10.8065 12 12 12ZM12 13.5C10.4087 13.5 8.88258 12.8679 7.75736 11.7426C6.63214 10.6174 6 9.0913 6 7.5C6 5.9087 6.63214 4.38258 7.75736 3.25736C8.88258 2.13214 10.4087 1.5 12 1.5C13.5913 1.5 15.1174 2.13214 16.2426 3.25736C17.3679 4.38258 18 5.9087 18 7.5C18 9.0913 17.3679 10.6174 16.2426 11.7426C15.1174 12.8679 13.5913 13.5 12 13.5Z"
                fill="var(--golden)"
              />
              <path
                d="M12 12C12.1989 12 12.3897 12.079 12.5303 12.2197C12.671 12.3603 12.75 12.5511 12.75 12.75V18.75C12.75 18.9489 12.671 19.1397 12.5303 19.2803C12.3897 19.421 12.1989 19.5 12 19.5C11.8011 19.5 11.6103 19.421 11.4697 19.2803C11.329 19.1397 11.25 18.9489 11.25 18.75V12.75C11.25 12.5511 11.329 12.3603 11.4697 12.2197C11.6103 12.079 11.8011 12 12 12Z"
                fill="var(--golden)"
              />
            </svg>
            <span>{{ $store.state.site_info?.city?.name }}</span>
          </div>
          <div class="header-phone">
            <a :href="'tel:' + $store.state.site_info?.city?.phone">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                  stroke="var(--golden)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                />
              </svg>
              {{ $store.state.site_info?.city?.phone }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import siteInfo from "@/assets/js/api/site-info.js";

export default {
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
    }
  },
  methods: {
    openMenu() {
      this.$store.commit("headerMenuOpen", true);
    },
    scrollHeader() {
      const app = document.querySelector("#app");
      app.addEventListener("scroll", () => {
        if (app.scrollTop > 50) {
          this.$store.commit("setScroll", true);
        } else {
          this.$store.commit("setScroll", false);
        }
      });
    },
    openCity() {
      this.$store.commit("cityMenuOpen", true);
    },
  },
  async mounted() {
    this.scrollHeader();

    const site_info = await siteInfo.get();
    this.$store.commit("setSiteInfo", site_info);
  },
};
</script>
<style scoped>
header {
  padding: 10px 0px;
  position: fixed;
  background-color: var(--white);
  top: 0;
  left: 0;
  z-index: 100;
  transition: 0.5s;
  width: 100%;
}
@media screen and (min-width: 1201px) {
  header {
    width: calc(100% - 10px);
  }
}

header.shadow {
  box-shadow: 0 0 10px rgba(52, 52, 52, 0.5);
  padding: 5px 0px;
}

header.shadow .header-logo {
  width: 106px;
  height: 60px;
}

.container.header-container {
  max-width: 1940px;
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
}

.header-menu-btn {
  width: 49px;
  height: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  cursor: pointer;
}

.header-menu-btn span {
  width: 37px;
  height: 3px;
  background-color: var(--main-color);
  border-radius: 20px;
}

.header-logo {
  width: 172px;
  height: 98px;
  cursor: pointer;
  transition: 0.5s;
}

.header-contacts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.choos-city,
.header-phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  cursor: pointer;
}

.header-phone a {
  text-decoration: none;
  color: var(--grey);
}

.header-phone span {
  color: var(--golden);
}

@media screen and (max-width: 1200px) {
  .container.header-container {
    flex-direction: row-reverse;
  }
  .w50.align-right {
    display: none;
  }
  .w50 {
    width: auto;
  }

  header.shadow {
    box-shadow: 0 0 10px rgba(52, 52, 52, 0.5);
    padding: 10px 0px;
  }

  .header-logo,
  header.shadow .header-logo {
    width: 86px;
    height: 49px;
  }
  .header-menu-btn {
    width: 33px;
    height: 19px;
    gap: 0;
    justify-content: space-between;
  }
  .header-menu-btn span {
    height: 2px;
    width: 100%;
  }
}
</style>
