import axios from "axios";
axios.defaults.withCredentials = true;

class Api {
	constructor(params) {
		this.default_params = {
			url: 'https://бастион-дом.рф/v1/methods/',
            site_info: JSON.parse(localStorage.getItem('site_info')),
		}

		this.params = Object.assign(this.default_params, params);
	}

	async send(path, data = null, method = 'get') {
        try {
            const axios_params = {
                method,
                url: this.params.url + path,
                data
            }

            let result = await axios(axios_params);

            if (result.status != 200) {
                console.log(result);
                return;
            }

            return result;
        } catch (error) {
            return error;
        }
    }
}

export default Api;