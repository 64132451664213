<template>
  <SectionTitle :title="possibility_investment?.block_title">
    <div class="custom-content">
      <h3>{{ possibility_investment?.title }}</h3>
      <div class="number-block-list">
        <NumberBlock
          v-for="(item, index) in possibility_investment?.texts"
          :index="index"
          :text="item.text"
        />
      </div>
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
import NumberBlock from "../UI/NumberBlock.vue";
export default {
  name: "InvestmentMain",
  components: {
    SectionTitle,
    NumberBlock,
  },
  props: {
    title: "",
    possibility_investment: {
      block_title: "",
      texts: [],
      title: "",
    },
  },
};
</script>
<style>
.custom-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-area: 1 / 2 / 2 / 4;
  gap: 20px;
}
.custom-content .number-block-list {
  grid-column: 1 / span 2;
  grid-area: auto;
}

.number-block-list {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  grid-area: 1 / 2 / 2 / 4;
}
.number-block-list.stage {
  padding-left: 86px;
}
@media screen and (max-width: 1200px) {
  .number-block-list {
    padding-left: 40px;
    grid-area: auto;
  }
  .custom-content {
    grid-area: auto;
  }
}
</style>
