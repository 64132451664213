<template>
  <section>
    <div class="container section-row" :class="is_block ? 'block' : ''">
      <div class="section-title" :class="link ? 'with-btn' : ''">
        <h2>{{ title }}</h2>
        <h3 class="subtitle" v-if="subtitle">{{ subtitle }}</h3>
        <Button v-if="link" :color="color_btn" :link="link">{{
          btn_text
        }}</Button>
      </div>
      <slot></slot>
    </div>
  </section>
</template>
<script>
import Button from "./Button.vue";
export default {
  name: "SectionTitle",
  components: {
    Button,
  },
  props: {
    title: "",
    subtitle: "",
    link: "",
    btn_text: "",
    color_btn: "",
    is_block: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
.section-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}

.section-row.block {
  display: block;
}

.section-row:has(.project__wrapper.plan) {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.section-row .section-title {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.custom-content h3 {
  margin-top: -10px;
}
.subtitle {
  grid-area: 1 / 2 / 2 / 4;
}
.section-title.with-btn .button-link {
  grid-column: 3;
  align-self: flex-start;
  justify-self: end;
}
@media screen and (max-width: 1200px) {
  .subtitle {
    grid-area: auto;
  }
  .custom-content h3 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .section-row {
    gap: 35px;
  }
  .section-row .section-title {
    grid-template-columns: 1fr;
  }
  .section-row .section-title h2 {
    min-width: 100%;
  }
  .section-row .button-link {
    margin: 0 auto;
  }
}
</style>
