<template>
    <div class="pagination-pages" v-if="count_pages > 1">
        <div class="pag-left">
            <button class="pag-to-start" @click="toStartPag">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 32 24" fill="none">
                    <path
                        d="M14.9998 19.9203L8.47984 13.4003C7.70984 12.6303 7.70984 11.3703 8.47984 10.6003L14.9998 4.08026"
                        stroke="var(--grey)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M22.9998 19.9203L16.4798 13.4003C15.7098 12.6303 15.7098 11.3703 16.4798 10.6003L22.9998 4.08026"
                        stroke="var(--grey)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
            <button class="pag-prev" @click="toPrevPag">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M14.9998 19.9203L8.47984 13.4003C7.70984 12.6303 7.70984 11.3703 8.47984 10.6003L14.9998 4.08026"
                        stroke="var(--grey)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
        </div>
        <div class="pagination_slider" ref="slider">
            <div class="pagination-pages-btn" ref="pages_slider">
                <button ref="pages" class="pag-to-page" :class="current_page - 1 == index ? 'active' : ''"
                    v-for="(btn, index) in count_pages" @click="toPagePag(index + 1)">
                    {{ index + 1 }}
                    <span></span>
                </button>
            </div>
        </div>
        <div class="pag-right">
            <button class="pag-next" @click="toNextPag">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M9.00016 4.07974L15.5202 10.5997C16.2902 11.3697 16.2902 12.6297 15.5202 13.3997L9.00016 19.9197"
                        stroke="var(--grey)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
            <button class="pag-to-end" @click="toEndPag">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 32 24" fill="none">
                    <path
                        d="M17.0002 4.07974L23.5202 10.5997C24.2902 11.3697 24.2902 12.6297 23.5202 13.3997L17.0002 19.9197"
                        stroke="var(--grey)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M9.00016 4.07974L15.5202 10.5997C16.2902 11.3697 16.2902 12.6297 15.5202 13.3997L9.00016 19.9197"
                        stroke="var(--grey)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        current_page: {
            type: Number,
            default: () => 1
        },
        count_pages: {
            type: Number,
            default: () => 1
        },
    },
    methods: {
        toStartPag() {
            this.$emit('change', 1);
        },
        toPrevPag() {
            if (this.current_page > 1) {
                this.$emit('change', this.current_page - 1);
            }
        },
        toPagePag(index) {
            this.$emit('change', index);
        },
        toNextPag() {
            if (this.current_page < this.count_pages) {
                this.$emit('change', this.current_page + 1);
            }
        },
        toEndPag() {
            this.$emit('change', this.count_pages);
        },
    },
    emits: ['change']
};
</script>
<style scoped>
button {
    border: none;
    background-color: transparent;
    outline: none;
    width: 35px;
    height: 35px;
}

.pagination-pages-btn button {
    min-width: 100px;
}

button svg path {
    transition: 0.5s;
}

button:hover svg path,
button:hover {
    stroke: var(--golden);
    color: var(--golden);
}

.pagination-pages {
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.pagination_slider {
    overflow: hidden;
    height: 36px;
    max-width: calc(100px * 4 + 10px * 3);
}

.pagination-pages-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    transition: 0.5s;
}

.pag-left,
.pag-right {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 3px 0px;
}

.pag-to-page {
    color: var(--main-color);
    font-size: 24px;
    font-weight: 600;
    line-height: 124.5%;
    transition: 0.5s;
    position: relative;
}

.pag-to-page.active {
    color: var(--golden);
}

.pag-to-page:hover span,
.pag-to-page.active span {
    width: 100px;
}

.pag-to-page span {
    content: "";
    position: absolute;
    top: 100%;
    width: 0;
    height: 1px;
    background-color: var(--golden);
    left: 50%;
    transform: translateX(-50%);
    transition: 0.5s;
}

@media screen and (max-width: 1200px) {
    .pagination-pages {
        margin-top: 50px;
        gap: 10px;
    }

    .pag-left,
    .pag-right {
        gap: 10px;
    }

    .pagination-pages-btn button,
    .pag-to-page:hover span,
    .pag-to-page.active span {
        min-width: 60px;
        width: 60px;
    }

    button {
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
    }

    .pagination_slider {
        height: 21px;
    }
}
</style>
