<template>
  <a :href="'/projects/' + slug" class="catalog-card">
    <div class="catalog-card-img"><img :src="img" alt="" /></div>
    <div class="catalog-card-info">
      <div class="catalog-card-title">
        <div class="catalog-card-name">{{ name }}</div>
        <div class="catalog-card-price pc" v-if="price">
          Стоимость: {{ text_price }} руб.
        </div>
      </div>
      <div class="catalog-card-desc">{{ desc }}</div>
      <div class="catalog-card-price mob" v-if="price">
        Стоимость: {{ text_price }} руб.
      </div>
    </div>
  </a>
</template>
<script>
import { numberWithSpaces } from "@/assets/js/helper.js";

export default {
  name: "Card",
  props: {
    slug: "",
    img: "",
    name: "",
    desc: "",
    price: "",
  },
  computed: {
    text_price() {
      return numberWithSpaces(this.price);
    },
  },
};
</script>
<style scoped>
.catalog-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.catalog-card:hover .catalog-card-info > *,
.catalog-card:hover .catalog-card-title > * {
  color: var(--golden);
}

.catalog-card-img {
  width: 100%;
  height: 100%;
  max-height: 400px;
}

.catalog-card-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}

.catalog-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.catalog-card-title > * {
  transition: 0.5s;
}

.catalog-card-name {
  color: var(--main-color);
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
.catalog-card-desc {
  color: var(--grey);
  font-size: 16px;
  font-weight: 600;
  line-height: 128.5%;
  transition: 0.5s;
  margin-top: 20px;
}

.catalog-card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.catalog-card-price {
  color: #585858;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  white-space: nowrap;
}
.catalog-card-price.mob {
  display: none;
}
@media screen and (max-width: 1200px) {
  .houses-list {
    grid-template-columns: 1fr;
    gap: 50px;
  }
  .catalog-card-name {
    font-size: 22px;
  }
  .catalog-card-desc {
    margin-top: 15px;
    font-size: 14px;
  }
  .catalog-card-price {
    font-size: 18px;
  }
  .catalog-card-price.mob {
    display: block;
  }
  .catalog-card-price.pc {
    display: none;
  }
  .catalog-card-img {
    min-height: 250px;
    max-height: 250px;
  }
  .catalog-card-info {
    flex-direction: column;
  }
}
</style>
