<template>
  <div class="hover_card">
    <div class="hover_card-back">
      <img :src="img" alt="" />
    </div>
    <div class="hover_card-front">
      <div class="hover_card-content-header">
        <h3>{{ name }}</h3>
        {{ role }}
      </div>
      <div class="hover_card-img-mobile">
        <img :src="img" alt="" />
      </div>
      <div class="hover_card-content-description">
        <div class="description-item">
          {{ desc }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HoverCard",
  props: {
    img: "",
    name: "",
    desc: "",
    role: "",
  },
};
</script>
<style>
.hover_card {
  width: 100%;
  border: 1px solid #343434;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 0.5s;
  min-height: 420px;
}

.hover_card-back {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: 0.5s;
}

.hover_card-back img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hover_card-front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  position: relative;
  z-index: 1;
  background-color: #fff;
  pointer-events: none;
  transition: 0.5s;
  height: 100%;
  min-height: 420px;
}
.hover_card-img-mobile {
  display: none;
}
@media screen and (min-width: 1200px) {
  .hover_card:hover {
    border: none;
  }
  .hover_card:hover .hover_card-back {
    transform: translateY(-150px) rotate(-15deg);
  }
  .hover_card:hover .hover_card-front {
    transform: translateY(50px) rotate(15deg);
    background-color: #cd9c1c;
  }
  .hover_card:hover .hover_card-front * {
    color: #fff;
  }
}
@media screen and (max-width: 1200px) {
  .hover_card-img-mobile {
    display: block;
  }
  .hover_card-img-mobile img {
    width: 100%;
    height: 200px;
    max-height: 200px;
  }
  .hover_card-front {
    gap: 20px;
  }
}
</style>
