<template>
  <div class="city-drop-container">
    <div class="city-drop-header" @click="toggleDrop()">
      <p>Город {{ $store.state.site_info?.city?.name }}</p>
      <div :class="is_active ? 'active' : ''" class="city-drop-btn" ref="btn">
        <span class="city-drop-line"></span>
        <span class="city-drop-line second"></span>
      </div>
    </div>
    <div
      class="city-drop-item"
      ref="city_drop_item"
      :class="is_active ? 'active' : ''"
    >
      <div
        class="city-drop-item-city"
        v-for="item in $store.state.site_info?.cities"
      >
        <p @click="selectCity(item?.id)">Город {{ item?.name }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import siteInfo from "@/assets/js/api/site-info";
import { mapState } from "vuex";
export default {
  name: "CityDropItem",
  data() {
    return {
      is_active: false,
      max_height: 0,
    };
  },
  methods: {
    async selectCity(id) {
      const site_info = await siteInfo.get(id, true);
      this.$store.commit("setSiteInfo", site_info);
      this.is_active = !this.is_active
      const drop_item = this.$refs.city_drop_item;
      drop_item.style.maxHeight = "";
    },
    toggleDrop() {
      if (!this.is_active) {
        const drop_item = this.$refs.city_drop_item;
        drop_item.style.maxHeight = this.max_height + 150 + "px";
      } else {
        const drop_item = this.$refs.city_drop_item;
        drop_item.style.maxHeight = "";
      }
      this.is_active = !this.is_active;
    },
  },
  computed: {
    ...mapState({
      site_info: (state) => state.site_info,
    }),
  },
  mounted() {
    const drop_item = this.$refs.city_drop_item;
    this.max_height = drop_item.scrollHeight;
  },
};
</script>
<style>
.city-drop-container {
  width: 100%;
}

.city-drop-container:not(:first-of-type) {
  margin-top: 30px;
}

.city-drop-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  padding-bottom: 6px;
}

.city-drop-btn {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--golden);
}

.city-drop-btn.active .city-drop-line {
  transform: rotate(-45deg);
}

.city-drop-btn.active .city-drop-line.second {
  transform: rotate(45deg);
}

.city-drop-line {
  transition: 0.4s;
  position: absolute;
  top: 50%;
  /* left: 50%; */
  width: 10px;
  transform: rotate(45deg) translate(-2px, 3px);
  border-top: 1px solid var(--golden);
}

.city-drop-line.second {
  transform: rotate(-45deg) translate(2px, 3px);
}

.city-drop-item {
  max-height: 0px;
  overflow: hidden;
  transition: 0.8s;
  opacity: 0;
}

.city-drop-item.active {
  opacity: 1;
}
.city-drop-header p {
  color: var(--grey);
  font-family: Mont;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
}
.city-drop-item-city p {
  padding: 6px 0;
}
</style>
