<template>
  <div class="DetailsaleHouseView">
    <SaleProject :title="page_data.title" :main_info="page_data.main_info" />
    <SaleProjectPlan :schema="page_data.schema" />
    <Calculator
      :block_title="page_data.calculator?.block_title"
      :title="page_data.calculator?.title"
      :home_price_min="page_data.calculator?.min_house_price"
      :home_price_max="page_data.calculator?.max_house_price"
      :first_contribution_min="page_data.calculator?.min_percent"
      :first_contribution_max="page_data.calculator?.max_percent"
      :types="page_data.calculator?.type"
    />
    <SectionOrder
      :title="page_data.data?.block_name"
      :form="page_data.data?.form"
    />
  </div>
</template>
<script>
import SaleProject from "@/components/detail-for-sale/SaleProject.vue";
import SaleProjectPlan from "@/components/detail-for-sale/SaleProjectPlan.vue";
import Calculator from "@/components/UI/Calculator.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";

import { mapActions, mapState } from "vuex";
export default {
  name: "DetailsaleHouseView",
  components: {
    SaleProject,
    SaleProjectPlan,
    Calculator,
    SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("completes/" + this.$route.params.slug);
  },
};
</script>
