<template>
  <SectionTitleRow :title="'Планировка дома'">
    <div class="sale-project-plan">
      <ProjectSlider :swiper_id="'project_plan'" :imgs="schema?.imgs" />
      <div class="sale-project-plan-contents">
        <div class="sale-project-plan-content">
          <div class="plan-content-block" v-for="block in schema?.descs">
            <h3 class="plan-content-title">{{ block.title }}</h3>
            <div class="plan-content-infos">
              <div class="plan-content-info" v-for="point in block.points">
                {{ point.text }}
              </div>
            </div>
          </div>
        </div>
        <Button :color="'orange'" @click="openPopup">Оставить заявку</Button>
      </div>
    </div>
  </SectionTitleRow>
</template>
<script>
import SectionTitleRow from "../UI/SectionTitleRow.vue";
import ProjectSlider from "../UI/ProjectSlider.vue";
import Button from "../UI/Button.vue";

export default {
  name: "SaleProjectPlan",
  components: {
    SectionTitleRow,
    ProjectSlider,
    Button,
  },
  props: {
    schema: {},
  },
  methods: {
    openPopup() {
      this.$store.commit("examplePopup", true);
    },
  },
};
</script>
<style>
.sale-project-plan {
  display: flex;
  gap: 60px;
  justify-content: space-between;
}

.sale-project-plan-contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sale-project-plan-content {
  display: flex;
  gap: 15px;
}

.plan-content-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.plan-content-infos {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.plan-content-info {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: 106%;
}

@media screen and (max-width: 1200px) {
  .sale-project-plan {
    flex-direction: column;
    gap: 20px;
  }
  .sale-project-plan-contents {
    gap: 50px;
  }
}
</style>
