import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/HomeView.vue'
import Company from '@/views/CompanyView.vue'
import NotFound from '@/views/404View.vue'
import Page from '@/views/PageView.vue'
import Components from '@/views/ComponentsView.vue'
import SocialObjectsView from '@/views/SocialObjectsView.vue'
import StocksView from '@/views/StocksView.vue'
import MortgageView from '@/views/MortgageView.vue'
import InvestmentView from '@/views/InvestmentView.vue'
import ContactsView from '@/views/ContactsView.vue'
import IndProjectView from '@/views/IndProjectView.vue'
import PartnersView from '@/views/PartnersView.vue'
import Vacancy from '@/views/VacancyView.vue'
import DetailsaleHouseView from '@/views/DetailsaleHouseView.vue'
import ReadyHouseView from '@/views/ReadyHouseView.vue'
import OurObjectsView from '@/views/OurObjectsView.vue'
import ProductionView from '@/views/ProductionView.vue'
import DetailProjectView from '@/views/DetailProjectView.vue'
import SerialProjectsView from '@/views/SerialProjectsView.vue'
import DesigningView from '@/views/DesigningView.vue'
import CatalogView from '@/views/CatalogView.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/company',
		name: 'Company',
		component: Company
	},
	{
		path: '/vacancy',
		name: 'Vacancy',
		component: Vacancy
	},
	{
		path: '/production',
		name: 'production',
		component: ProductionView
	},
	{
		path: '/social-objects',
		name: 'social-objects',
		component: SocialObjectsView
	},
	{
		path: '/stocks',
		name: 'stocks',
		component: StocksView
	},
	{
		path: '/mortgage',
		name: 'mortgage',
		component: MortgageView
	},
	{
		path: '/investment',
		name: 'investment',
		component: InvestmentView
	},
	{
		path: '/contacts',
		name: 'contacts',
		component: ContactsView
	},
	{
		path: '/individual_projects',
		name: 'individual-projects',
		component: IndProjectView
	},
	{
		path: '/partners',
		name: 'partners',
		component: PartnersView
	},
	// {
	// 	//детальная страница дома на продажу
	// 	path: '/complete/:slug',
	// 	name: 'detail-sale-house',
	// 	component: DetailsaleHouseView
	// },
	{
		//Готовые дома на продажу
		path: '/completes',
		name: 'ready-houses',
		component: ReadyHouseView
	},
	{
		//Наши объекты
		path: '/our_objects',
		name: 'our_objects',
		component: OurObjectsView
	},
	{
		//детальная страница объектов
		path: '/projects/:slug',
		name: 'detail-project',
		component: DetailProjectView
	},
	{
		path: '/projects',
		name: 'catalog',
		component: CatalogView
	},
	{
		path: '/serial_projects',
		name: 'serial-projects',
		component: SerialProjectsView
	},
	{
		path: '/designing',
		name: 'designing',
		component: DesigningView
	},
	{
		path: '/ys/components',
		name: 'components',
		component: Components
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		component: Page
	},
	{
		path: '/404',
		component: NotFound
	},
	{
		path: '/:catchAll(.*)',
		redirect: '/404'
	},
]

const router = createRouter({
	history: createWebHistory('/'),
	routes,
})

export default router
