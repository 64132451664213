<template>
  <SectionTitle :title="review?.block_title" v-if="review?.show">
    <div class="text-title reviews-text">
      <h3>{{ review?.title }}</h3>
      <SliderReviews
        :swiper_id="'reviews'"
        :slidesPerView="2"
        :reviews="review?.reviews"
      />
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
import SliderReviews from "../UI/SliderReviews.vue";

export default {
  components: {
    SectionTitle,
    SliderReviews,
  },
  props: {
    review: {
      show: false,
      block_title: "",
      title: "",
      reviews: [],
    },
  },
};
</script>
<style>
.text-title.reviews-text {
  width: 100%;
}
@media screen and (min-width: 1201px) {
  .text-title.reviews-text {
    grid-area: 1 / 2 / 2 / 4;
  }
}
@media screen and (max-width: 1200px) {
  .text-title.reviews-text {
    max-width: none;
    grid-column: 1 / span 2;
  }
}
</style>
