<template>
  <div class="contacts">
    <ContactsMain :title="page_data.title" :card="page_data.data?.card" />
    <ContactsAddress :cities="page_data.data?.cities" />
    <ContactsMap />
    <SectionOrder
      :title="page_data.data?.block_name"
      :form="page_data.data?.form"
    />
  </div>
</template>
<script>
import ContactsMain from "@/components/contacts/ContactsMain.vue";
import ContactsAddress from "@/components/contacts/ContactsAddress.vue";
import ContactsMap from "@/components/contacts/ContactsMap.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "ContactsView",
  components: {
    ContactsMain,
    ContactsAddress,
    ContactsMap,
    SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("contacts");
  },
};
</script>
<style></style>
