<template>
  <div class="investment">
    <div class="container">
      <h1>{{ page_data.title }}</h1>
    </div>
    <InvestmentMain
      :possibility_investment="page_data.data?.possibility_investment"
    />
    <InvestmentAchievments :achievement="page_data.data?.achievement" />
    <InvestmentImgSection
      :investment_conditions="page_data.data?.investment_conditions"
    />
    <SectionOrder
      :title="page_data.data?.form?.block_name"
      :form="page_data.data?.form"
    />
  </div>
</template>
<script>
import InvestmentMain from "@/components/investment/InvestmentMain.vue";
import InvestmentAchievments from "@/components/investment/InvestmentAchievments.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import InvestmentImgSection from "@/components/investment/InvestmentImgSection.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "InvestmentView",
  components: {
    InvestmentMain,
    InvestmentAchievments,
    InvestmentImgSection,
    SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/investment");
  },
};
</script>
<style></style>
