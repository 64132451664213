<template>
  <section id="saleProject">
    <div class="container">
      <div class="sale__project">
        <div class="sale__project-header">
          <h1 class="sale__project-title">{{ title }}</h1>
          <div class="sale__project-square">{{ main_info?.area }} м</div>
        </div>
        <div class="sale__project-content">
          <ProjectSliderPag
            :swiper_id="'project_slider'"
            :gallery="main_info?.gallery"
          />
          <div class="sale__project-info">
            <div class="project-info-block">
              <div class="project-info-title">Стоимость дома</div>
              <!-- <div class="project-info-value">{{ main_info?.price }} ₽</div> -->
              <div class="project-info-value">{{ housePrice }} ₽</div>
            </div>
            <div class="project-info-grid">
              <div class="project-info-block">
                <div class="project-info-title">Общая площадь</div>
                <div class="project-info-value">{{ main_info?.area }} м</div>
              </div>
              <div class="project-info-block">
                <div class="project-info-title">Материал</div>
                <div class="project-info-value">
                  {{ main_info?.material.post_title }}
                </div>
              </div>
              <div class="project-info-block">
                <div class="project-info-title">Количество этажей</div>
                <div class="project-info-value">{{ main_info?.floors }}</div>
              </div>
              <div class="project-info-block">
                <div class="project-info-title">Количество комнат</div>
                <div class="project-info-value">{{ main_info?.rooms }}</div>
              </div>
            </div>
            <div class="project-info-block">
              <div class="project-info-title">Размер дома</div>
              <div class="project-info-sizes">
                <div class="project-info-value">
                  {{ main_info?.length }} м <span>длина</span>
                </div>
                <div class="project-info-value">
                  {{ main_info?.width }} м <span>ширина</span>
                </div>
                <div class="project-info-value">
                  {{ main_info?.height }} м <span>высота</span>
                </div>
              </div>
            </div>
            <Button :color="'orange'" @click="popupOpen"
              >Оставить заявку</Button
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";
import ProjectSliderPag from "../UI/ProjectSliderPag.vue";
import { numberWithSpaces } from "@/assets/js/helper.js";

export default {
  name: "SaleProject",
  components: {
    Button,
    ProjectSliderPag,
  },
  props: {
    title: "",
    main_info: {
      area: "",
      gallery: [],
    },
  },
  methods: {
    popupOpen() {
      this.$store.commit("examplePopup", true);
    },
  },
  computed: {
    housePrice() {
      return numberWithSpaces(this.main_info?.price);
    },
  },
};
</script>
<style>
#saleProject {
  padding-top: 50px;
}

.sale__project {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: var(--white);
  box-shadow: 4px 5px 8px 5px rgba(88, 88, 88, 0.08);
  padding: 50px;
}

.sale__project-header {
  display: flex;
  align-items: center;
  gap: 25px;
}

.sale__project-title {
  width: auto;
  padding: 0;
  margin: 0;
  font-size: 40px;
  line-height: 106%;
}

.sale__project-square {
  color: var(--grey);
  font-size: 24px;
  font-weight: 600;
  line-height: 106%;
}

.sale__project-content {
  display: flex;
  justify-content: space-between;
  gap: 60px;
}

.Slider {
}

.sale__project-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.project-info-block {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.project-info-price .project-info-title {
  font-size: 20px;
}

.project-info-price .project-info-value {
  font-size: 40px;
}

.project-info-title {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: 106%;
}

.project-info-value {
  color: var(--main-color);
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
}

.project-info-value span {
  color: var(--grey);
  font-size: 14px;
  font-weight: 600;
  line-height: 106%;
}

.project-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px 30px;
}

.project-info-sizes {
  display: flex;
  gap: 12px;
  align-items: flex-end;
}

@media screen and (max-width: 1200px) {
  .sale__project-info .button-link {
    min-width: auto;
  }
  .sale__project-content {
    flex-direction: column;
    gap: 30px;
  }
  .project-info-sizes {
    flex-wrap: wrap;
  }
  .sale__project {
    padding: 20px;
  }
  .sale__project-title {
    font-size: 28px;
    line-height: 30px; /* 107.143% */
  }
  .sale__project-header {
    gap: 10px;
    align-items: flex-end;
  }
  .sale__project-square {
    min-width: 50px;
    font-size: 18px;
    line-height: 106%; /* 19.08px */
  }
  .project-info-value {
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
  }
  .project-info-grid {
    gap: 20px 30px;
  }
  #saleProject {
    padding-top: 0;
  }
}
@media screen and (max-width: 400px) {
  .project-info-grid {
    grid-template-columns: 1fr;
  }
}
</style>
