<template>
  <section id="team-content">
    <div class="container">
      <div class="section-wrapper">
        <div class="section-title">
          <h2>{{ team?.block_title }}</h2>
        </div>
        <div class="text-title reviews-text">
          <h3 class="reviews-text-title">{{ team?.title }}</h3>
          <div class="history-content">
            <div class="history-content-item" v-for="item in team?.texts">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <SliderCard
        :swiper_id="'team'"
        :slidesPerView="3"
        :workers="team?.workers"
      />
    </div>
  </section>
</template>
<script>
import SliderCard from "../UI/SliderCard.vue";
import HoverCard from "@/components/UI/HoverCard.vue";
import SectionTitle from "@/components/UI/SectionTitle.vue";

export default {
  components: {
    SliderCard,
    SectionTitle,
    HoverCard,
  },
  props: {
    team: {
      block_title: "",
      texts: [],
      title: "",
      workers: [],
    },
  },
};
</script>
<style scoped>
#team-content .slider__wrapper {
  max-width: 100%;
}

.reviews-slide {
  width: 100%;
  height: auto;
  background: #fff;
  box-shadow: 4px 5px 8px 5px rgba(88, 88, 88, 0.08);
  margin: 4px 0px 11px -11px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 87px;
}

.reviews-slide p {
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.5%; /* 23.13px */
}

.reviews-user {
  display: flex;
  flex-direction: row;
  gap: 34px;
}

.reviews-slide .user-name {
  color: #343434;
  font-family: Mont;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 124.5%;
}

.reviews-slide .user-solution {
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.5%;
}

.reviews-slide img {
  max-width: 100px;
}

.container.slider-container {
  padding: 0px 100px;
}

.reviews-text .two-columns {
  width: 100%;
}

.reviews-text-title {
  margin-bottom: 40px;
}

.overswiper {
  background-color: red;
}
@media screen and (max-width: 1200px) {
  .reviews-text-title {
    margin-bottom: 10px;
  }
  #team-content .container:nth-child(2) {
    margin-top: 35px;
  }
}
</style>
