<template>
  <div class="filters-menu" :class="$store.state.filtersMenuOpen ? 'open' : ''">
    <div class="filters-menu-bg" @click="close()"></div>
    <div class="filters-menu-popup">
      <div class="close-filters-menu" @click="close()">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.3125 5.6875L5.6875 20.3125"
            stroke="var(--grey)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.3125 20.3125L5.6875 5.6875"
            stroke="var(--grey)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="filters-menu-title">Фильтры</div>
      <div class="filters-menu-list">
        <div class="filters-menu-list-item" v-for="(filter, key) in filters">
          <template v-if="key != 'material'">
            <span>{{ getLabel(key) }}</span>
            <div class="filters-menu-inputs">
              <div class="filters-menu-input">
                <label for="">от: </label>
                <input
                  type="text"
                  v-model="filters[key].min"
                  :placeholder="filters[key].placeholder_min"
                  @change="change(key)"
                />
              </div>
              <div class="filters-menu-input">
                <label for="">до: </label>
                <input
                  type="text"
                  v-model="filters[key].max"
                  :placeholder="filters[key].placeholder_max"
                  @change="change(key)"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="filters-menu-list-item">
              <span>{{ getLabel(key) }}</span>
              <label
                :for="'material_' + material.id"
                v-for="material in possible_filters.material"
              >
                <input
                  :id="'material_' + material.id"
                  type="checkbox"
                  :value="material.id"
                  v-model="filters.material"
                  @change="change(key)"
                />
                {{ material.name }}
              </label>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="filters-menu-btns" :class="is_changed ? 'open' : ''">
      <div class="button orange" @click="send">Применить</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    possible_filters: {},
    applied_filters: {},
    type: {
      type: String,
      default: () => 'objects',
    },
  },
  data() {
    return {
      materials: [],
      filters: {
        price: {},
        floors: {},
        rooms: {},
        length: {},
        width: {},
        height: {},
        area: {},
        material: [],
      },
      is_changed: false,
    };
  },
  methods: {
    close() {
      this.$store.commit("filtersMenuOpen", false);
    },
    setFilter(value, is_applied = false) {
      const default_value = {
        placeholder_min: Number(value.min),
        placeholder_max: Number(value.max),
      };

      if (is_applied) {
        default_value.min = value.min;
        default_value.max = value.max;
      } else {
        default_value.min = "";
        default_value.max = "";
      }

      return default_value;
    },
    getLabel(key) {
      const labels = {
        price: "Цена",
        floors: "Кол-во этажей",
        rooms: "Кол-во комнат",
        area: "Общая площадь (м²)",
        length: "Длина (м)",
        width: "Ширина (м)",
        height: "Высота (м)",
        material: "Материалы",
      };

      return labels[key];
    },
    change(key) {
      if (key != "material") {
        this.validation(key);
      }

      this.is_changed = true;
    },
    validation(key) {
      if (this.filters[key].min != "") {
        if (this.filters[key].max != "") {
          if (this.filters[key].min > this.filters[key].max) {
            this.filters[key].min = this.filters[key].max;
          }
        }

        if (this.filters[key].min < this.filters[key].placeholder_min) {
          this.filters[key].min = this.filters[key].placeholder_min;
        }

        if (this.filters[key].min > this.filters[key].placeholder_max) {
          this.filters[key].min = this.filters[key].placeholder_max;
        }
      }

      if (this.filters[key].max != "") {
        if (this.filters[key].min != "") {
          if (this.filters[key].max < this.filters[key].min) {
            this.filters[key].max = this.filters[key].min;
          }
        }

        if (this.filters[key].max > this.filters[key].placeholder_max) {
          this.filters[key].max = this.filters[key].placeholder_max;
        }

        if (this.filters[key].max < this.filters[key].placeholder_min) {
          this.filters[key].max = this.filters[key].placeholder_min;
        }
      }
    },
    send() {
      let json_filters = localStorage.getItem("filters"),
        local_filters = {},
        prepared_filter = {};

      if (json_filters) {
        local_filters = JSON.parse(json_filters);
      }

      for (const key in this.filters) {
        if (key != "material") {
          const is_min = this.filters[key].min != "",
            is_max = this.filters[key].max != "";

          if (is_min || is_max) {
            prepared_filter[key] = {};

            if (is_min) {
              prepared_filter[key].min = Number(this.filters[key].min);
            }

            if (is_max) {
              prepared_filter[key].max = Number(this.filters[key].max);
            }
          }
        } else {
          prepared_filter.material = this.filters.material;
        }
      }

      local_filters[this.type] = prepared_filter;
      json_filters = JSON.stringify(local_filters);
      localStorage.setItem("filters", json_filters);

      this.is_changed = false;

      this.$emit("change");
    },
  },
  watch: {
    possible_filters(new_value) {
      if (new_value) {
        delete new_value.type;

        for (const key in new_value) {
          switch (key) {
            case "material":
              let filtered_material = [];

              new_value.material.forEach((material) => {
                const checked = Boolean(
                  this.applied_filters?.material.find(
                    (applied_material) => applied_material.id == material.id
                  )
                );
                if (checked) {
                  filtered_material.push(material.id);
                }
              });

              if (new_value.material.length > filtered_material.length) {
                this.filters.material = filtered_material;
              }
              break;

            default:
              this.filters[key] = this.setFilter(
                new_value[key],
                this.applied_filters[key]
              );
              break;
          }
        }
      }
    },
  },
  emits: ["change"],
};
</script>
<style>
.filters-menu {
  color: var(--grey);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 136.364% */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  pointer-events: none;
}

.filters-menu.open {
  pointer-events: all;
}

.filters-menu-popup {
  position: relative;
  z-index: 10;
  background-color: var(--white);
  width: 100%;
  max-width: 489px;
  padding: 100px 56px 40px 100px;
  overflow: auto;
  height: 100lvh;
  transform: translateX(-100%);
  transition: 0.8s;
}

.filters-menu.open .filters-menu-popup {
  transform: translateX(0%);
}

.filters-menu-bg {
  background: rgba(52, 52, 52, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: 0.8s;
}

.filters-menu.open .filters-menu-bg {
  opacity: 1;
  pointer-events: all;
}

.close-filters-menu {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.filters-menu-title {
  font-size: 32px;
}

.filters-menu-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 0;
}

.filters-menu-list-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--lightgrey);
}

.filters-menu-list-item label {
  font-size: 18px;
}

.filters-menu-list-item:last-of-type {
  border-bottom: none;
}

.filters-menu-inputs {
  display: flex;
  gap: 20px;
}

.filters-menu-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.filters-menu-input input[type="text"] {
  outline: none;
  font-size: 18px;
  line-height: 18px;
  width: 100%;
  max-width: 200px;
  padding: 5px;
  border: none;
  border-bottom: 1px solid var(--grey);
}

.filters-menu-input input[type="text"]::placeholder {
  color: var(--lightgrey);
  font-size: 18px;
}

.filters-menu-btns {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  max-width: 479px;
  background-color: var(--white);
  left: 0;
  bottom: 0;
  padding: 20px 0;
  transform: translateX(-100%);
  transition: 0.8s;
}

.filters-menu.open .filters-menu-btns.open {
  transform: translateX(0);
}

@media screen and (max-width: 1200px) {
  .filters-menu-popup {
    padding: 112px 20px 20px 20px;
  }
}
</style>
