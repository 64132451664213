<template>
  <div class="filters-panel">
    <div class="filter-btn" @click="open">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.4 2.1001H18.6C19.7 2.1001 20.6 3.0001 20.6 4.1001V6.3001C20.6 7.1001 20.1 8.1001 19.6 8.6001L15.3 12.4001C14.7 12.9001 14.3 13.9001 14.3 14.7001V19.0001C14.3 19.6001 13.9 20.4001 13.4 20.7001L12 21.6001C10.7 22.4001 8.9 21.5001 8.9 19.9001V14.6001C8.9 13.9001 8.5 13.0001 8.1 12.5001L4.3 8.5001C3.8 8.0001 3.4 7.1001 3.4 6.5001V4.2001C3.4 3.0001 4.3 2.1001 5.4 2.1001Z"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>
        Фильтры
        <span v-if="count_filters > 0">: {{ count_filters }}</span>
      </span>
    </div>
    <div class="filter-splitter" v-if="count_filters > 0">|</div>
    <div class="filter-btn" v-if="count_filters > 0" @click="$emit('reset')">
      <svg
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.3125 5.6875L5.6875 20.3125"
          stroke="var(--grey)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.3125 20.3125L5.6875 5.6875"
          stroke="var(--grey)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      Сбросить все
    </div>
  </div>
</template>
<script>
export default {
  props: {
    applied_filters: {},
    possible_filters: {},
  },
  data() {
    return {
      material: [],
      count_filters: 0,
    };
  },
  methods: {
    open() {
      this.$store.commit("filtersMenuOpen", true);
    },
  },
  watch: {
    applied_filters(new_value) {
      if (new_value) {
        let count = 0;
        for (const key in new_value) {
          if (key != "type" && key != "material") {
            count++;
          } else if (key == "material") {
            let filtered_material = [];

            if (this.possible_filters.material) {
              new_value.material.forEach(material => {
                  const checked = Boolean(this.possible_filters?.material.find(possible_material => possible_material.id == material.id));
                  if (checked) {
                      filtered_material.push(material.id);
                  }
              });

              if (
                this.possible_filters.material.length >
                  filtered_material.length ||
                this.possible_filters.material.length == 1
              ) {
                count++;
              }
            }

          }
        }

        this.count_filters = count;
      }
    },
  },
  emits: ["reset"],
};
</script>
<style>
.filters-panel {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  color: var(--grey);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 136.364% */
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: 0.5s;
}

.filter-btn:hover {
  color: var(--golden);
}

.filter-btn svg path {
  stroke: var(--grey);
  transition: 0.5s;
}

.filter-btn:hover svg path {
  stroke: var(--golden);
}

.filter-splitter {
  padding: 0 30px;
}

@media screen and (max-width: 1200px) {
  .filter-btn {
    font-size: 16px;
  }
  .filter-splitter {
    padding: 0 15px;
  }
}
</style>
