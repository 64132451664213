<template>
  <SectionTitleRow
    :title="serial_title"
    :color_btn="'white'"
    :link="link"
    :btn_text="'Перейти в каталог →'"
  >
    <div class="houses-list">
      <Card
        v-for="item in completes"
        :slug="item.id"
        :img="item.data?.main_info.preview_img"
        :name="item.title"
        :desc="item.data?.main_info.preview_desc"
        :price="item.data?.main_info.price"
      />
    </div>
  </SectionTitleRow>
</template>
<script>
import SectionTitleRow from "./SectionTitleRow.vue";
import Card from "@/components/catalog/Card.vue";
export default {
  name: "GridExamples",
  components: {
    SectionTitleRow,
    Card,
  },
  props: {
    completes: [],
    serial_title: "",
    link: "",
  },
};
</script>
<style>
.houses-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}
@media screen and (max-width: 1200px) {
  .houses-list {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
</style>
