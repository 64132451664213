<template>
  <div :class="'popup ' + ($store.state.answerOpen ? 'active' : '')">
    <div class="popup-bg" @click="close"></div>
    <div class="popup-container">
      <div class="popup-header">
        <slot name="header"> </slot>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          @click="close"
        >
          <path
            d="M20.3125 5.6875L5.6875 20.3125"
            stroke="#C5C5C5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.3125 20.3125L5.6875 5.6875"
            stroke="#C5C5C5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="order-answer-content" v-if="$store.state.resultSuccess == true">
        <div class="order-answer-img">
          <img :src="require('@/assets/img/success.png')" alt="" />
        </div>
        <div class="order-answer-text">
          <h2 class="order-answer-title">Заявка успешно <br />отправлена</h2>
          <div class="order-answer-subtitle">
            С Вами свяжутся в течение суток!
          </div>
        </div>
        <button class="button orange" @click="close">Окей</button>
      </div>
      <div class="order-answer-content" v-else>
        <div class="order-answer-img">
          <img :src="require('@/assets/img/success.png')" alt="" />
        </div>
        <div class="order-answer-text">
          <h2 class="order-answer-title">Заявка не <br />отправлена</h2>
          <div class="order-answer-subtitle">Повторите попытку позже!</div>
        </div>
        <button class="button orange" @click="close">Окей</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "orderAnswer",
  methods: {
    close() {
      this.$store.commit("answerOpen", false);
    },
  },
};
</script>
<style>
.order-answer-img {
  max-width: 262px;
  max-height: 262px;
}
.order-answer-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.order-answer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
}
.order-answer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.order-answer-title {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  line-height: 51px; /* 102% */
}
.order-answer-subtitle {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 25px; /* 113.636% */
}
</style>
