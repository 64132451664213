<template>
  <SectionTitleRow :title="partner?.block_title" :is_block="true">
    <div class="partners-slider-container">
      <DoubleSlider
        :slidesPerView="4"
        :swiper_id="'partners'"
        :partners="partner?.partners"
      />
    </div>
 
    <Button
      :color="'white'"
      :link="'/partners'"
      v-if="$router.currentRoute.value.path.indexOf('partners') < 0"
      >Партнерам →</Button
    >
  </SectionTitleRow>
</template>
<script>
import SectionTitleRow from "../UI/SectionTitleRow.vue";
import DoubleSlider from "../UI/DoubleSlider.vue";
import Button from "@/components/UI/Button.vue";
export default {
  name: "PartnerPartners",
  components: {
    DoubleSlider,
    SectionTitleRow,
    Button,
  },
  props: {
    partner: {
      block_title: "",
      partners: [],
    },
  },
};
</script>

<style>
.slider__wrapper:has(#partners) {
  margin-top: -40px;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px 0;
}
.slider:has(#partners) {
  grid-column: 1 / span 2;
}
.slide-block {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.slide-block-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.slide-block-img {
  height: 150px;
  width: 100%;
}
.slide-block-img img {
  width: 100%;
  height: 100%;
  max-height: 150px;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}

.slide-block-name {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}
.slider-btns {
  justify-self: end;
}
@media screen and (max-width: 1200px) {
  .slider__wrapper:has(#partners) {
    margin-top: 0;
  }
  .slide-block {
    gap: 10px;
  }
}
</style>
