<template>
  <div class="mobile-btns">
    <div :class="'button-prev proj-prev ' + swiper_id">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
      >
        <rect x="0.5" y="0.5" width="45" height="45" stroke="var(--golden)" />
        <path
          d="M18.9512 33.1167L8.83451 23L18.9512 12.8834"
          stroke="var(--golden)"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.166 23.0005L9.11602 23.0005"
          stroke="var(--golden)"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div :class="'button-next proj-next ' + swiper_id">
      <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="45.5"
          y="45.5"
          width="45"
          height="45"
          transform="rotate(-180 45.5 45.5)"
          stroke="#CD9C1C"
        />
        <path
          d="M27.0488 12.8835L37.1655 23.0002L27.0488 33.1169"
          stroke="#CD9C1C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.83398 23L36.884 23"
          stroke="#CD9C1C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
  <div
    class="slider__wrapper project__wrapper"
    :class="swiper_id == 'project_plan' ? 'plan' : ''"
  >
    <div :class="'project-prev proj-prev ' + swiper_id">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="22"
        viewBox="0 0 12 22"
        fill="none"
      >
        <path
          d="M11 21L1 11L11 1"
          stroke="#CD9C1C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="slider">
      <swiper-container :id="swiper_id" init="false">
        <swiper-slide v-for="item in imgs">
          <img :src="item" alt="" class="swiper-img contain" />
        </swiper-slide>
      </swiper-container>
    </div>
    <div :class="'project-next proj-next ' + swiper_id">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="22"
        viewBox="0 0 12 22"
        fill="none"
      >
        <path
          d="M1 1L11 11L1 21"
          stroke="#CD9C1C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import { register } from "swiper/element/bundle";

export default {
  name: "ProjectSliderPag",
  props: {
    swiper_id: {
      type: String,
      required: true,
    },
    imgs: [],
  },
  mounted() {
    register();
    const swiperEl = document.querySelector("#" + this.swiper_id);
    const swiperParams = {
      slidesPerView: 1,
      navigation: {
        nextEl: ".proj-next",
        prevEl: ".proj-prev",
      },
    };
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  },
};
</script>
<style>
.slider__wrapper.project__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  max-width: calc(100% - 500px);
  position: relative;
  width: 100%;
}

.slider__wrapper.project__wrapper.plan {
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.project-slider__wrapper swiper-slide {
  max-width: 675px;
}

.slider {
  width: 100%;
}

.project-next.project_plan,
.project-prev.project_plan {
  position: static;
  transform: translateY(0%);
}

.project-next,
.project-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}

.project-prev {
  left: -30px;
}

.project-next {
  right: -30px;
}

.swiper-img {
  max-height: 300px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.swiper-img.contain {
  object-fit: contain;
}
.swiper-button-lock {
  opacity: 0;
}
@media screen and (max-width: 1200px) {
  .slider__wrapper.project__wrapper {
    gap: 12px;
    max-width: none;
  }
  .project-next,
  .project-prev {
    display: none;
  }
  .slider__wrapper.project__wrapper.plan {
    flex-direction: column;
  }
  .swiper-img {
    max-height: 180px;
    min-height: 180px;
  }
}
</style>
