<template>
  <div class="simple-block">
    <h3>{{ title }}</h3>
    <div class="simple-block-text">{{ text }}</div>
  </div>
</template>
<script>
export default {
  name: "SimpleBlock",
  props: {
    title: "",
    text: "",
  },
};
</script>
<style>
.grid-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 70px 30px;
  grid-area: 1 / 2 / 2 / 4;
}

.simple-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.simple-block h3 {
  min-height: auto;
}

.simple-block-text {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .grid-content {
    display: flex;
    flex-direction: column;
    grid-area: auto;
  }
  .grid-content .drop-header {
    padding: 0;
    box-shadow: none;
  }
  .grid-content .drop-item {
    padding: 0;
  }
}
</style>
