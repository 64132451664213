<template>
  <a :href="link" class="button-link">
    <div :class="'button ' + color">
      <slot></slot>
    </div>
  </a>
</template>
<style>
.button-link {
  display: block;
}
.button {
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px 36px;
  width: max-content;
  cursor: pointer;
  transition: 0.5s;
  height: max-content;
  white-space: nowrap;
}

.button.orange {
  color: var(--white);
  background: var(--golden);
  border: 1px solid var(--golden);
}

.button.orange:hover {
  background: var(--white);
  border: 1px solid var(--golden);
  color: var(--golden);
}

.button.white {
  background: var(--white);
  border: 1px solid var(--golden);
  color: var(--golden);
}

.button.white:hover {
  color: var(--white);
  background: var(--golden);
}

.button.grey {
  color: var(--grey);
  border: 1px solid var(--grey);
}

.button.grey:hover {
  color: var(--white);
  background: var(--golden);
  border: 1px solid var(--golden);
}

@media screen and (max-width: 1200px) {
  .button-link {
    width: max-content;
    min-width: 320px;
    margin: 0 auto;
  }
  .button {
    width: 100%;
    max-width: 320px;
    text-align: center;
    font-size: 16px;
  }
}
</style>
<script>
export default {
  props: {
    color: "",
    link: "",
  },
};
</script>