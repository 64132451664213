<template>
  <div :class="'header-menu' + ($store.state.headerMenuOpen ? ' active' : '')">
    <div class="header-menu-bg" @click="closeMenu()"></div>
    <div class="header-menu-popup">
      <div class="close-popup" @click="closeMenu()">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.3125 5.6875L5.6875 20.3125"
            stroke="var(--grey)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.3125 20.3125L5.6875 5.6875"
            stroke="var(--grey)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <a href="/" class="header-menu-link">
        <img
          class="header-menu-popup-logo"
          :src="$store.state.site_info?.info.header_logo ?? logo"
          alt=""
        />
      </a>
      <div
        class="header-menu-popup-item"
        v-for="menu_group in $store.state.site_info?.menu"
      >
        <ul>
          <li v-for="menu_item in menu_group">
            <a :href="menu_item.link">{{ menu_item.title }}</a>
          </li>
        </ul>
        <hr />
      </div>
      <div class="header-menu-city">
        <div class="header-menu-popup-item">
          <CityDropItem />
          <hr />
        </div>
      </div>
      <div class="header-menu-popup-item">
        <p>{{ $store.state.site_info?.city?.address }}</p>
        <div class="header-phone menu-phone">
          <a :href="'tel:' + $store.state.site_info?.city?.phone">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                stroke="var(--golden)"
                stroke-width="1.5"
                stroke-miterlimit="10"
              />
            </svg>
            {{ $store.state.site_info?.city?.phone }}
          </a>
        </div>
        <div
          class="time-work"
          v-html="$store.state.site_info?.city?.work_time"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import CityDropItem from "@/components/UI/CityDropItem.vue";

export default {
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
    }
  },
  methods: {
    closeMenu() {
      this.$store.commit("headerMenuOpen", false);
    },
  },
  components: {
    CityDropItem,
  },
};
</script>
<style>
.header-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
}

.header-menu.active {
  pointer-events: all;
}

.header-menu-popup {
  position: relative;
  z-index: 10;
  background-color: var(--white);
  width: 100%;
  max-width: 489px;
  padding: 100px 56px 40px 120px;
  overflow: auto;
  height: 100lvh;
  transform: translateX(-100%);
  transition: 0.8s;
}

.header-menu-bg {
  background: rgba(52, 52, 52, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: 0.8s;
}

.header-phone.menu-phone {
  margin-top: 20px;
  margin-bottom: 25px;
}

.header-menu.active .header-menu-bg {
  opacity: 1;
  pointer-events: all;
}

.header-menu.active .header-menu-popup {
  transform: translateX(0px);
}

.header-menu-popup-logo {
  position: absolute;
  left: 25px;
  top: 25px;
  width: 100px;
  height: 57px;
  display: none;
}

.header-menu-popup {
  scrollbar-color: #999 #333;
}

.header-menu-popup::-webkit-scrollbar {
  width: 10px;
  border-left: 1px solid #f0f0f0;
}

.header-menu-popup::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #c1c1c1;
  width: 8px;
  height: 25px;
}

.header-menu-popup::-webkit-scrollbar-track {
  background: transparent;
}

.header-menu-popup-item ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.header-menu-popup-item li a {
  color: var(--grey);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.header-menu-popup-item ul li a:hover {
  color: var(--golden);
}

.header-menu-popup-item hr {
  opacity: 0.6;
  margin: 0;
  background: var(--lightgrey);
  max-width: 272px;
  width: 100%;
  height: 1px;
  margin: 25px 0px;
}

.header-phone a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  cursor: pointer;
  text-decoration: none;
  color: var(--grey);
}

.header-menu-popup-item p {
  color: var(--grey);
  font-family: Mont;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.time-work {
  color: var(--grey);
  font-family: Mont;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.time-work span {
  color: var(--golden);
}

.close-popup {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.header-menu-city {
  display: none;
}
@media screen and (max-width: 1200px) {
  .header-menu-city {
    display: block;
  }
  .header-menu-popup {
    max-width: none;
    padding: 112px 5px 20px 20px;
  }

  .header-menu-popup-logo {
    display: block;
  }

  .header-menu-popup-item hr {
    max-width: none;
  }
}
</style>
