<template>
  <Main :main_banner="page_data.data?.main_banner" />
  <Banner :stock="page_data.data?.stock" />
  <About :about="page_data.data?.about" />
  <Process
    :processes_left="page_data.data?.processes_left"
    :processes_right="page_data.data?.processes_right"
    :processes_block_title="page_data.data?.processes_block_title"
    :processes_title="page_data.data?.processes_title"
  />
  <Project
    :completes="page_data.data?.completes"
    :completes_block_title="page_data.data?.completes_block_title"
  />
  <Mortgage
    :mortgage="page_data.data?.mortgage"
    :link="'mortgage'"
    color_btn="grey"
    :btn_text="'Подробнее об условиях →'"
  />
  <Reviews :review="page_data.data?.review" />
  <SectionOrder
    :title="page_data.data?.block_name"
    :form="page_data.data?.form"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";

import Main from "@/components/home/main.vue";
import Banner from "@/components/UI/Banner.vue";
import About from "@/components/home/About.vue";
import Process from "@/components/home/process.vue";
import Project from "@/components/home/projects.vue";
import Reviews from "@/components/home/reviews.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import Mortgage from "@/components/mortgage/Mortgage.vue";
import SectionTitle from "@/components/UI/SectionTitle.vue";

export default {
  name: "HomeView",
  components: {
    Main,
    SectionTitle,
    SectionOrder,
    Mortgage,
    Process,
    Project,
    Reviews,
    Banner,
    About,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("home");
  },
};
</script>
