<template>
  <Main :title="page_data.title" :main_banner="page_data.data?.main_banner" />
  <Capacity :production="page_data.data?.production" />
  <SectionTitle :title="page_data.data?.technology.block_title">
    <Equipment :equipments="page_data.data?.technology.technologies" />
  </SectionTitle>
  <IndividualProjectsCondition :process="page_data.data?.process" />
  <Controls :control="page_data.data?.control" />
  <Serteficat :serteficate="page_data.data?.certificate" />
  <SectionOrder
    :title="page_data.data?.block_name"
    :form="page_data.data?.form"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";

import Main from "@/components/production/ProductionMain.vue";
import Capacity from "@/components/production/Capacity.vue";
import Equipment from "@/components/production/Equipment.vue";
import SectionTitle from "@/components/UI/SectionTitle.vue";
import Controls from "@/components/production/Controls.vue";
import Serteficat from "@/components/company/serteficat.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import IndividualProjectsCondition from "@/components/Individual-projects/IndividualProjectsCondition.vue";

export default {
  name: "production",
  components: {
    Main,
    Capacity,
    Equipment,
    SectionTitle,
    SectionOrder,
    Serteficat,
    IndividualProjectsCondition,
    Controls,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/production");
  },
};
</script>
