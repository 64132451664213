import Api from "./index.js";

class PostsApi extends Api {
    async getMore (post_type = 'post', pagination = {}) {

        const default_pagination = {
            page: 1,
            posts_per_page: 2,
        }

        pagination = Object.assign(default_pagination, pagination);

        const data = {
            post_type,
            pagination
        }

        const result = await this.send('posts', data, 'POST');

        return result?.data;
    }
}

export default new PostsApi();