<template>
  <SectionTitle :title="condition?.block_title">
    <div class="custom-content">
      <h3>{{ condition?.title }}</h3>
      <div class="number-block-list">
        <NumberBlock
          v-for="(item, index) in condition?.conditions"
          :index="index"
          :text="item.text"
        />
      </div>
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
import NumberBlock from "../UI/NumberBlock.vue";
export default {
  name: "PartnerCondition",
  components: {
    SectionTitle,
    NumberBlock,
  },
  props: {
    condition: {
      block_title: "",
      title: "",
      conditions: [],
    },
  },
};
</script>
