<template>
  <div class="equipment-list">
    <SimpleBlock
      v-show="pc"
      v-for="item in equipments"
      :title="item.title"
      :text="item.text"
    />
    <DropList v-show="mobile" :tabs="equipments" />
  </div>
</template>
<script>
import SimpleBlock from "@/components/UI/SimpleBlock.vue";
import DropList from "../base/DropList.vue";
export default {
  components: {
    SimpleBlock,
    DropList,
  },
  props: {
    equipments: [],
  },
  data() {
    return {
      pc: true,
      mobile: false,
    };
  },
  methods: {
    change() {
      if (window.innerWidth > 1200) {
        this.pc = true;
        this.mobile = false;
      } else {
        this.pc = false;
        this.mobile = true;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.change);
  },
  destroyed() {
    window.removeEventListener("resize", this.change);
  },
  mounted() {
    this.change;
  },
};
</script>
<style>
.equipment-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 50px;
  grid-area: 1 / 2 / 2 / 4;
}
@media screen and (max-width: 1200px) {
  .equipment-list {
    display: flex;
    flex-direction: column;
    grid-area: auto;
  }
  .equipment-list .drop-header {
    padding: 0;
    box-shadow: none;
  }
  .equipment-list .drop-item {
    padding: 0;
  }
}
</style>
