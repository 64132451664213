<template>
  <SectionTitle :title="plus?.block_title">
    <div class="grid-content">
      <SimpleBlock
        v-show="pc"
        v-for="item in plus?.pluses"
        :text="item.text"
        :title="item.title"
      />
      <DropList v-show="mobile" :tabs="plus?.pluses" />
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
import SimpleBlock from "../UI/SimpleBlock.vue";
import DropList from "../base/DropList.vue";
export default {
  name: "PartnersAdvantages",
  components: {
    SectionTitle,
    SimpleBlock,
    DropList,
  },
  props: {
    plus: {
      block_title: "",
      pluses: [],
    },
  },
  data() {
    return {
      pc: true,
      mobile: false,
    };
  },
  methods: {
    change() {
      if (window.innerWidth > 1200) {
        this.pc = true;
        this.mobile = false;
      } else {
        this.pc = false;
        this.mobile = true;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.change);
  },
  destroyed() {
    window.removeEventListener("resize", this.change);
  },
  mounted() {
    this.change;
  },
};
</script>
<style></style>
