<template>
  <div class="drop-list">
    <NumberDropItem
      v-for="(item, index) in tabs"
      :header="item.title"
      :index="index"
    >
      <div
        class="number-block-text"
        v-if="
          $router.currentRoute.value.path.indexOf('individual_projects') > 0
        "
        v-html="item.text"
      ></div>
      <div class="" v-else>
        {{ item.text }}
      </div>
    </NumberDropItem>
  </div>
</template>
<script>
import NumberDropItem from "./NumberDropItem.vue";

export default {
  props: {
    tabs: [],
  },
  components: {
    NumberDropItem,
  },
};
</script>
<style>
.drop-list {
  width: 100%;
}
</style>
