import { createStore } from 'vuex'
import { pageModule } from './pageModule'
import { appModule } from './appModule'

export default createStore({
	state: () => {
		return {
			orderPopupShow: false,
			vacancy: null,
			is_scroll: false,
			mobileMenuOpen: false,
			mobileMenuClose: false,
			successFormOrder: false,
			answerOpen: false,
			budgetError: false,
			rateError: false,
			mapOpen: false,
			examplePopup: false,
			supportPopupShow: false,
			headerMenuOpen: false,
			cityMenuOpen: false,
			site_info: {
				cities: [],
				city: {},
				menu: {},
				info: {}
			},
			filtersMenuOpen: false,
			resultSuccess: false,
		}
	},
	getters: {

	},
	mutations: {
		headerMenuOpen(state, bool) {
			state.headerMenuOpen = bool;
		},
		cityMenuOpen(state, bool) {
			state.cityMenuOpen = bool;
		},
		filtersMenuOpen(state, bool) {
			state.filtersMenuOpen = bool;
		},
		examplePopup(state, bool) {
			state.examplePopup = bool;
		},
		setScroll(state, bool) {
			state.is_scroll = bool;
		},
		openMenu(state, bool) {
			state.mobileMenuOpen = bool;
		},
		closeMenu(state, bool) {
			state.mobileMenuClose = bool;
		},
		successOrder(state, bool) {
			state.successFormOrder = bool;
		},
		answerOpen(state, bool) {
			state.answerOpen = bool;
		},
		budgetError(state, bool) {
			state.budgetError = bool;
		},
		rateError(state, bool) {
			state.rateError = bool;
		},
		mapOpen(state, bool) {
			state.mapOpen = bool;
		},
		setVacancy(state, string) {
			state.vacancy = string;
		},
		openSupport(state, bool) {
			state.supportPopupShow = bool;
		},
		setSiteInfo(state, object) {
			state.site_info = object;
		},
		resultSuccess(state, bool) {
			state.resultSuccess = bool;
		}
	},
	actions: {

	},
	modules: {
		page: pageModule,
		app: appModule
	}
})
