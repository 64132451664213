<template>
    <section id="mainSocialObjects">
        <div class="container">
            <div class="mainSocialObjects-title">
                <h1>{{ title }}</h1>
            </div>
            <div class="imgTitleBlock-list">
                <ImgTitleBlocks 
					v-for="(item, index) in social_objects" 
					:reverse="((index + 1) % 2 === 0)" 
					:title="item.title" 
					:img="item.img"
					:text="item.content" 
				/>
                <div class="more-btn">
                    <Button 
                        v-if="is_can_more" 
                        color="white"
                        @click="getMoreSocialObjects"
                    >
                        Загрузить ещё
                    </Button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import postsApi from '@/assets/js/api/posts.js'

import ImgTitleBlocks from "@/components/UI/ImgTitleBlocks.vue";
import Button from "@/components/UI/Button.vue";

export default {
    name: "MainSocialObjects",
    components: {
        ImgTitleBlocks,
        Button,
    },
    props: {
        title: "",
    },
    data() {
        return {
            page: 1,
            social_objects: [],
            is_can_more: false,
        }
    },
    methods: {
        async getMoreSocialObjects() {
            const result = await postsApi.getMore('social-objects', {
                page: this.page
            });

            if (result) {
                this.social_objects.push(...result.posts);
                this.page++;
                this.is_can_more = result.is_can_more;
            }
        }
    },
    async created() {
        await this.getMoreSocialObjects();
    }
};
</script>
<style>
.more-btn {
    margin-top: -130px;
    height: max-content;
    justify-self: center;
}

@media screen and (max-width: 1200px) {
    .more-btn {
        margin: 0;
    }
}
</style>
