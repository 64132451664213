<template>
  <div v-if="page_data.projects.length > 0">
    <FiltersMenu
      :possible_filters="page_data.possible_filters"
      :applied_filters="page_data.applied_filters"
      :type="type"
      @change="changeFilters"
    />
    <Filters
      :applied_filters="page_data.applied_filters"
      :possible_filters="page_data.possible_filters"
      @reset="resetFilters"
    />
    <div class="catalog">
      <Card
        v-for="(house, index) in page_data.projects"
        :key="index"
        :slug="house.slug"
        :img="house.img"
        :name="house.name"
        :desc="house.desc"
        :price="house.price"
      />
    </div>
    <Pagination
      :count_pages="page_data.count_pages"
      :current_page="page_data.page"
      @change="changePage"
    />
  </div>
  <div class="empty-catalog" v-else>Нет готовых домов на продажу</div>
</template>

<script>
import { mapState } from "vuex";

import FiltersMenu from "./FiltersMenu.vue";
import Filters from "./Filters.vue";
import Card from "./Card.vue";
import Pagination from "./Pagination.vue";

import projectsApi from "@/assets/js/api/projects.js";

export default {
  name: "Catalog",
  components: {
    FiltersMenu,
    Filters,
    Pagination,
    Card,
  },
  props: {
    type: {
      type: String,
      default: () => "objects",
    },
  },
  async created() {
    const json_filters = localStorage.getItem("filters");

    if (json_filters) {
      const local_filters = JSON.parse(json_filters);
      this.filters = local_filters[this.type];
    } else {
      this.filters = {
        type: this.type,
      };
    }

    const projects = await projectsApi.get(this.filters, this.pagination);
    if (projects) {
      this.page_data = projects;
    }
  },
  methods: {
    async changeFilters() {
      const json_filters = localStorage.getItem("filters");
      let local_filters = {};

      if (json_filters) {
        local_filters = JSON.parse(json_filters);
      }

      this.filters = local_filters[this.type];

      this.filters = {
        type: this.type,
        ...this.filters,
      };

      const projects = await projectsApi.get(
        this.filters,
        this.pagination,
        this.site_info.city.id
      );
      if (projects) {
        this.page_data = projects;
      }
    },
    async resetFilters() {
      const json_filters = localStorage.getItem("filters");
      let local_filters = {};

      if (json_filters) {
        local_filters = JSON.parse(json_filters);
      }

      delete local_filters[this.type];
      localStorage.setItem("filters", JSON.stringify(local_filters));

      this.filters = {
        type: this.type,
      };

      const projects = await projectsApi.get(
        this.filters,
        this.pagination,
        this.site_info.city.id
      );
      if (projects) {
        this.page_data = projects;
      }
    },
    async changePage(page) {
      const json_filters = localStorage.getItem("filters");
      let local_filters = {};

      if (json_filters) {
        local_filters = JSON.parse(json_filters);
      }

      this.filters = local_filters[this.type];

      this.filters = {
        type: this.type,
        ...this.filters,
      };

      this.pagination.page = page;

      const projects = await projectsApi.get(
        this.filters,
        this.pagination,
        this.site_info.city.id
      );
      if (projects) {
        this.page_data = projects;
      }
    },
  },
  computed: {
    ...mapState({
      site_info: (state) => state.site_info,
    }),
  },
  data() {
    return {
      page_data: {
        projects: [],
        count_pages: 1,
        page: 1,
        possible_filters: {},
        applied_filters: {},
      },
      filters: {},
      pagination: {
        page: 1,
        posts_per_page: 10,
      },
    };
  },
  watch: {
    site_info() {
      this.changeFilters();
    },
  },
};
</script>
<style>
.catalog {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px 30px;
}

.empty-catalog {
  color: var(--grey);
  font-family: Mont;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .catalog {
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
  }
  .empty-catalog {
    font-size: 16px;
    line-height: 30px;
    height: 30vh;
  }
}
</style>
