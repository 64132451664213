<template>
  <div class="stocks">
    <MainStocks :title="page_data?.title" />
    <SectionOrder
      :title="page_data.data?.block_name"
      :form="page_data.data?.form"
    />
  </div>
</template>
<script>
import MainStocks from "@/components/stocks/MainStocks.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";

import { mapState, mapActions } from "vuex";
export default {
  name: "SocialObjectsView",
  components: {
    MainStocks,
    SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/stocks");
  },
};
</script>
<style></style>
