<template>
  <SectionTitleRow :title="completes_block_title" v-if="completes.length > 0">
    <div class="projects-content-grid">
      <a
        :href="'/projects/' + item.slug"
        class="project"
        v-for="(item, index) in completes"
        :class="'project' + (index + 1)"
      >
        <img :src="item?.data.main_info.preview_img" alt="" />
        <div class="project-desc">
          <div class="title">
            <span>{{ item?.title }}</span>
            {{ item?.data.main_info.preview_desc }}
          </div>
          <div class="content">
            <p v-if="item?.data.main_info.price">
              Стоимость: {{ text_price(item?.data.main_info.price) }} р
            </p>
            <!-- <p v-if="item?.data.main_info.mortgage">
              Ипотека: {{ item?.data.main_info.mortgage }}
            </p> -->
          </div>
        </div>
      </a>
      <div class="mobile-btn">
        <Button :color="'white'" :link="'/our_objects'">Проекты →</Button>
      </div>
    </div>
  </SectionTitleRow>
</template>
<script>
import { numberWithSpaces } from "@/assets/js/helper.js";
import SectionTitleRow from "../UI/SectionTitleRow.vue";
import Button from "@/components/UI/Button.vue";

export default {
  components: {
    Button,
    SectionTitleRow,
  },
  props: {
    completes: {
      type: Array,
      default: () => [],
    },
    completes_block_title: "",
  },
  methods: {
    text_price(price) {
      return numberWithSpaces(price);
    },
  },
};
</script>
<style>
.projects-content-grid {
  display: grid;
  grid-template-columns: 1fr calc(100% / 3 - 15px);
  gap: 30px;
}

.projects-title {
  margin-bottom: 40px;
}

.project {
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  row-gap: 20px;
}

.project .title {
  display: flex;
  flex-direction: column;
  color: #585858;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.5%;
  gap: 20px;
}

.project .title span {
  color: #343434;
  font-family: Mont;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 124.5%;
}

.project.project1 .project-desc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.project.project1 img {
  max-height: 500px;
}
.project.project2 img {
  max-height: 300px;
}
.project3 .project-desc {
  width: 100%;
}
.project .project-desc {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.project3 {
  display: flex;
  flex-direction: row;
  max-height: 380px;
  height: 100%;
  align-self: flex-end;
}

.project3 img {
  max-width: 50%;
}

.project4 img {
  min-height: 400px;
  max-height: 400px;
}
.content p {
  color: var(--grey);
  font-size: 20px;
  font-weight: 600;
  line-height: 128.5%; /* 25.7px */
  white-space: nowrap;
}
@media screen and (max-width: 1200px) {
  .projects-content-grid {
    grid-template-columns: 1fr;
  }
  .project.project1 img,
  .project.project2 img,
  .project.project3 img,
  .project.project4 img {
    max-height: 250px;
    min-height: 250px;
  }
  .project {
    gap: 10px;
    flex-direction: column;
  }
  .projects-content {
    flex-direction: column;
    gap: 50px;
  }
  .left-column.w65,
  .right-column.w35 {
    width: 100%;
    gap: 50px;
  }
  .project.project1 .project-desc {
    flex-direction: column;
    gap: 10px;
  }
  .project .title span {
    font-size: 22px;
  }
  .project .title {
    font-size: 14px;
  }
  .content p {
    color: #585858;
    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 128.5%; /* 23.13px */
  }
  .project3 img {
    width: 100%;
    max-width: 100%;
  }
  .project3 {
    max-height: none;
  }
}
</style>
