<template>
  <div class="serial-projects">
    <MainIndividualProjects
      :title="page_data.title"
      :main_banner="page_data.data?.main_banner"
    />
    <history
      :history="page_data.data?.individual"
      :link="true"
      :btn_link="'/individual_projects'"
      :btn_text="'Индивидуальные проекты →'"
    />
    <history
      :history="page_data.data?.serial"
      :link="true"
      :btn_link="'/serial_projects'"
      :btn_text="'Серийные проекты →'"
    />
    <IndividualProjectsAdvantages :plus="page_data.data?.plus" />
    <GridExamples
      :completes="page_data.data?.completes"
      :serial_title="page_data.data?.designing_title"
      :link="'/our_objects'"
    />
    <SectionOrder
      :title="page_data.data?.block_name"
      :form="page_data.data?.form"
    />
  </div>
</template>
<script>
import MainIndividualProjects from "@/components/Individual-projects/MainIndividualProjects.vue";
import history from "@/components/company/history.vue";
import IndividualProjectsAdvantages from "@/components/Individual-projects/IndividualProjectsAdvantages.vue";
import GridExamples from "@/components/UI/GridExamples.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "SerialProjectsView",
  components: {
    MainIndividualProjects,
    history,
    IndividualProjectsAdvantages,
    GridExamples,
    SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("designing");
  },
};
</script>
<style></style>
