<template>
  <section>
    <div class="container">
      <h1>{{ title }}</h1>
      <Catalog :type="type" />
    </div>
  </section>
</template>
<script>
import Catalog from "@/components/catalog/Catalog.vue";

export default {
  name: "OurObjectsMain",
  components: {
    Catalog,
  },
  props: {
    type: "",
    title: "",
  },
};
</script>
