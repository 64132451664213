<template>
  <SectionTitle :title="achievement?.block_title">
    <SliderSertificat
      :swiper_id="'sertificate'"
      :slidesPerView="2"
      :certificates="achievement?.imgs"
    />
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
import SliderSertificat from "../UI/SliderSertificat.vue";
export default {
  name: "InvestmentAchievments",
  components: {
    SectionTitle,
    SliderSertificat,
  },
  props: {
    achievement: {
      block_title: "",
      imgs: [],
    },
  },
  data() {
    return {
      img: require("@/assets/img/stocks.jpg"),
      img: require("@/assets/img/stocks.jpg"),
      img: require("@/assets/img/stocks.jpg"),
      img: require("@/assets/img/stocks.jpg"),
      img: require("@/assets/img/stocks.jpg"),
    };
  },
};
</script>
<style></style>
