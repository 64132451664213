<template>
  <SectionTitle v-if="!link" :title="history?.block_title">
    <div class="history-section">
      <h3>{{ history?.title }}</h3>
      <div class="history-content">
        <div class="history-content-item" v-for="item in history?.texts">
          {{ item.text }}
        </div>
      </div>
    </div>
  </SectionTitle>
  <SectionTitle
    v-else
    :title="history?.block_title"
    :link="btn_link"
    :color_btn="'grey'"
    :btn_text="btn_text"
  >
    <div class="history-section">
      <h3>{{ history?.title }}</h3>
      <div class="history-content">
        <div class="history-content-item" v-for="item in history?.texts">
          {{ item.text }}
        </div>
      </div>
    </div>
  </SectionTitle>
  <div class="full-size-img" v-if="history?.img">
    <img class="baner company" :src="history?.img" />
  </div>
</template>
<script>
import SectionTitle from "@/components/UI/SectionTitle.vue";

export default {
  components: {
    SectionTitle,
  },
  props: {
    history: {
      block_title: "",
      img: "",
      title: "",
      texts: [],
    },
    link: false,
    btn_link: "",
    btn_text: "",
  },
};
</script>
<style>
.history-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-area: 1 / 2 / 2 / 4;
}
.history-content {
  display: flex;
  gap: 30px;
}

.history-content-item {
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  width: 100%;
}

.baner.company {
  margin-top: -130px;
  height: 300px;
}

@media screen and (max-width: 1200px) {
  .history-section {
    gap: 10px;
    grid-area: auto;
  }
  .history-content {
    flex-direction: column;
    gap: 20px;
  }
  .baner.company {
    height: 200px;
    margin-top: -45px;
  }
}
</style>
