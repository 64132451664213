<template>
  <SectionTitle
    :title="about?.block_title"
    :link="'/company'"
    :color_btn="'grey'"
    :btn_text="'Подробнее о компании →'"
  >
    <div class="about-content">
      <h3 class="content-title">{{ about?.title }}</h3>
      <div class="about-section">
        <div class="two-columns">
          <p v-for="item in about?.texts">{{ item.text }}</p>
        </div>
        <div class="achievements">
          <div class="achievements-item" v-for="item in about?.infos">
            <span>{{ item.title }}</span>
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
export default {
  name: "about",
  components: {
    SectionTitle,
  },
  props: {
    about: {
      block_title: "",
      title: "",
      texts: [],
      infos: [],
    },
  },
  data() {
    return {
      display: "",
    };
  },
  methods: {
    setBtn() {
      if (window.innerWidth > 1200) {
        this.display == "block";
      } else {
        this.display == "none";
      }
    },
  },
  update() {
    this.setBtn;
  },
  mounted() {
    this.setBtn();
  },
};
</script>
<style>
.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.two-columns p {
  width: 100%;
}
.about-section {
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.content-title {
  min-height: 80px;
}

.achievements {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 30px;
}

.achievements-item {
  display: flex;
  flex-direction: column;
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 138.889% */
}

.achievements-item span {
  color: #cd9c1c;
  font-family: Mont;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.about-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-area: 1 / 2 / 2 / 4;
  gap: 20px;
}
@media screen and (max-width: 1200px) {
  .two-columns {
    grid-template-columns: 1fr;
    gap: 25px;
  }
  .achievements {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .about-content {
    grid-area: auto;
  }
}
</style>
