<template>
  <TitleWithColumns>
    <template v-slot:title>
      <h1>{{ title }}</h1>
    </template>
    <div class="twc-list" :class="btn ? 'with-btn' : ''" v-if="btn">
      <div class="twc-list-text">{{ main_banner?.text }}</div>
      <Button :color="'white'" @click="openPopup">Оставить заявку</Button>
    </div>
    <div class="twc-list" :class="pic ? 'with-pic' : ''" v-if="pic">
      <div class="twc-list-text">{{ main_banner?.text }}</div>
      <div class="twc-list-img">
        <img :src="require('@/assets/img/stocks.jpg')" alt="" />
      </div>
    </div>
    <div class="twc-list" v-if="!btn && !pic">
      <div class=""></div>
      <div class="twc-list-text" v-for="item in main_banner?.texts">
        {{ item.text }}
      </div>
    </div>
  </TitleWithColumns>
</template>
<script>
import TitleWithColumns from "../UI/TitleWithColumns.vue";
import Button from "../UI/Button.vue";

export default {
  name: "MainIndividualProjects",
  components: {
    TitleWithColumns,
    Button,
  },
  props: {
    title: "",
    main_banner: {
      texts: [],
      text: "",
    },
    btn: false,
    img: false,
  },
  methods: {
    openPopup() {
      this.$store.commit("examplePopup", true);
    }
  }
};
</script>
