<template>
  <SectionTitleRow :title="this.block_title" :subtitle="this.title">
    <div class="graph-container" id="chartdiv">
      <div class="graph-loading" :class="is_loading ? 'active' : ''">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </SectionTitleRow>
</template>
<script>
import SectionTitleRow from "../UI/SectionTitleRow.vue";

import * as am5 from "@amcharts/amcharts5";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import projectsApi from "@/assets/js/api/projects.js";

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default {
  name: "Graph",
  components: {
    SectionTitleRow,
  },
  props: {
    graph: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      is_loading: false,
      block_title: "",
      title: "",
    };
  },
  methods: {
    async loadGraph() {
      this.is_loading = true;
      const graph = await projectsApi.getGraph();
      this.block_title = graph.block_title;
      this.title = graph.title;
      if (graph.stages.length > 0) {
        am5.ready(function () {
          var root = am5.Root.new("chartdiv");
          root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX", "openValueX"],
          });
          root.setThemes([am5themes_Animated.new(root)]);
          root.locale = am5locales_ru_RU;

          var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "panX",
              wheelY: "zoomX",
              layout: root.verticalLayout,
            })
          );

          var legend = chart.children.push(
            am5.Legend.new(root, {
              centerX: am5.p50,
              x: am5.p50,
            })
          );

          var data = [];
          let YLabels = [];
          let date = new Date();
          for (let index = 0; index < graph?.stages.length; index++) {
            YLabels.push({ category: graph.stages[index].category });
            data.push({
              category: graph.stages[index].category,
              fromDate: date.addDays(Number(graph.stages[index].fromDate)),
              toDate: date.addDays(Number(graph.stages[index].toDate)),
              columnSettings: {
                fill: graph.stages[index].color,
              },
            });
          }
          var yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              categoryField: "category",
              renderer: am5xy.AxisRendererY.new(root, {
                inversed: true,
              }),
              tooltip: am5.Tooltip.new(root, {
                themeTags: ["axis"],
                animationDuration: 200,
              }),
            })
          );
          yAxis.data.setAll(YLabels);

          var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
              baseInterval: { timeUnit: "day", count: 1 },
              renderer: am5xy.AxisRendererX.new(root, {
                tooltip: am5.Tooltip.new(root, {}),
              }),
            })
          );

          var series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              xAxis: xAxis,
              yAxis: yAxis,
              openValueXField: "fromDate",
              valueXField: "toDate",
              categoryYField: "category",
              sequencedInterpolation: true,
            })
          );

          series.columns.template.setAll({
            templateField: "columnSettings",
            strokeOpacity: 0,
            tooltipText:
              "{category}: {openValueX.formatDate('yyyy-MM-dd')} - {valueX.formatDate('yyyy-MM-dd')}",
          });

          series.data.processor = am5.DataProcessor.new(root, {
            dateFields: ["fromDate", "toDate"],
          });
          series.data.setAll(data);

          chart.set(
            "scrollbarX",
            am5.Scrollbar.new(root, {
              orientation: "horizontal",
            })
          );

          series.appear();
          chart.appear(1000, 100);
        });
      }
      this.is_loading = false;
    },
  },
  created() {
    this.loadGraph();
    console.log(this.graph);
  },
};
</script>
<style>
.graph-container {
  background: #fff;
  height: 700px;
  box-shadow: 4px 5px 8px 5px rgba(88, 88, 88, 0.08);
  position: relative;
}

.graph-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
}

.graph-loading.active {
  pointer-events: all;
  opacity: 1;
}

.graph-loading .lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.graph-loading .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid var(--golden);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--golden) transparent transparent transparent;
}

.graph-loading .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.graph-loading .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.graph-loading .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
