<template>
  <div class="order__contacts-map" ref="map" :style="'height:' + height"></div>
</template>
<script>
export default {
  name: "Map",
  props: {
    height: "",
  },
  mounted() {
    const map = document.createElement("script");
    map.setAttribute(
      "src",
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A16265955f691ea721e2236471ddf242754d57204425322850af07475a11b2fda&amp;width=auto&amp;height=auto&amp;lang=ru_RU&amp;scroll=true"
    );
    this.$refs.map.appendChild(map);
  },
};
</script>
<style>
@media screen and (max-width: 1200px) {
  #contact-map .order__contacts-map {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
</style>
