<template>
  <Popup type="examplePopup">
    <PopupForm @send="openAnswer"></PopupForm>
  </Popup>
</template>
<script>
import Popup from "@/components/base/Popup.vue";
import PopupForm from "@/components/forms/PopupForm.vue";

export default {
  components: {
    Popup,
    PopupForm,
  },
  methods: {
    openAnswer() {
      this.$store.commit("examplePopup", false);
      this.$store.commit("answerOpen", true);
    },
  },
};
</script>
