<template>
  <section id="not-found" class="first">
    <div class="container not-found">
      <h1><span>404</span>Ошибка</h1>
      <div class="not-found-content">
        <h3>
          Страница была удалена, либо <br class="mobile" />никогда не
          существовала
        </h3>
        <Button color="orange" @click="toHome">Вернуться на главную</Button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import Button from "@/components/UI/Button.vue";

export default {
  components: {
    Button,
  },
  methods: {
    ...mapMutations({
      setLoading: "page/setLoading",
    }),
    toHome() {
      document.location.href = "/";
    },
  },
  mounted() {
    this.setLoading(false);
  },
};
</script>

<style scoped>
#not-found {
  margin-bottom: 100px;
}

h1 {
  font-size: 8vh;
  line-height: 8vh;
  width: max-content;
}

h1 span {
  font-size: 20vh;
  line-height: 20vh;
  display: block;
}

.container.not-found {
  display: flex;
  align-items: center;
  gap: 65px;
  padding: 0 120px;
}

.not-found-content {
  display: flex;
  flex-direction: column;
}
.not-found-content h3 {
  padding-top: 32px;
  margin-bottom: 45px;
}
.not-found-content .button-link {
  margin-top: -10px;
}

@media screen and (max-width: 1200px) {
  #not-found {
    margin-bottom: 0;
  }
  .container.not-found {
    flex-direction: column;
    gap: 30px;
    padding: 0 20px;
  }
  h1 {
    margin-bottom: 0;
    font-size: 64px;
    line-height: 100%; /* 64px */
  }
  h1 span {
    font-size: 128px;
    line-height: 100%; /* 128px */
    text-align: center;
    margin-bottom: -10px;
  }
  h3 {
    text-align: center;
    font-size: 24px;
    line-height: 100%; /* 24px */
  }
  .not-found-content {
    align-items: center;
  }
}
</style>
