<template>
  <section id="main">
    <div class="container main-container">
      <h1 v-html="main_banner?.title"></h1>
      <div class="main-content">
        <p>
          {{ main_banner?.text }}
        </p>
        <Button color="white" @click="popupOpen()"
          >Оставить заявку на проект</Button
        >
        <Button color="grey" :link="main_banner?.btn_link">{{
          main_banner?.btn_text
        }}</Button>
      </div>
    </div>
  </section>
  <div class="full-size-img">
    <img :src="main_banner?.img" alt="" />
  </div>
</template>
<script>
import Button from "@/components/UI/Button.vue";

export default {
  components: {
    Button,
  },
  props: {
    main_banner: {
      img: "",
      text: "",
      title: "",
      img: "",
    },
  },
  methods: {
    popupOpen() {
      this.$store.commit("examplePopup", true);
    },
  },
};
</script>
<style scoped>
section#main {
  padding-bottom: 0;
  margin-bottom: 45px;
}
.main-container h1 {
  width: 60%;
}
.main-content p {
  width: 355px;
  color: var(--grey);
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.main-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.main-content .button-link:first-of-type {
  justify-self: end;
}

.main-btns {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.full-size-img {
  width: 100%;
  margin-bottom: 180px;
}
.full-size-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .main-container h1 {
    width: 100%;
  }
  .main-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .main-content .button-link,
  .main-content .button-link:first-of-type {
    width: 100%;
    justify-self: center;
    width: max-content;
    min-width: 320px;
  }
  .main-content p {
    width: 100%;
  }
  .main-btns {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .full-size-img img {
    height: 200px;
  }
  .full-size-img {
    margin-bottom: 80px;
  }
}
</style>
