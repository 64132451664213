<template>
  <section id="contacts">
    <div class="container">
      <h1>{{ title }}</h1>
    </div>
  </section>
  <SectionTitle :title="card?.block_title">
    <div class="card_org">
      <h3>{{ card?.title }}</h3>
      <div class="card_org-info">
        <div class="card_org-row">
          <div class="card_org-row-title">ИНН</div>
          <h3 class="card_org-row-text">{{ card?.inn }}</h3>
        </div>
        <div class="card_org-row">
          <div class="card_org-row-title">ОГРН</div>
          <h3 class="card_org-row-text">{{ card?.kpp }}</h3>
        </div>
        <div class="card_org-row">
          <div class="card_org-row-title">Юр. адрес</div>
          <h3 class="card_org-row-text">{{ card?.address }}</h3>
        </div>
      </div>
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
export default {
  name: "ContactsMain",
  components: {
    SectionTitle,
  },
  props: {
    title: "",
    card: {
      address: "",
      block_title: "",
      inn: "",
      kpp: "",
      title: "",
    },
  },
};
</script>
<style>
section#contacts {
  padding-bottom: 100px;
}
.card_org {
  grid-area: 1 / 2 / 2 / 4;
  background: var(--white);
  box-shadow: 4px 5px 8px 5px rgba(88, 88, 88, 0.08);
  padding: 30px;
  width: 100%;
}

.card_org-info {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.card_org-row {
  display: flex;
  gap: 150px;
  align-items: center;
}

.card_org-row-title {
  color: var(--lightgrey);
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  min-width: 170px;
}

.card_org-row-text {
  font-size: 26px;
  line-height: 40px;
}
@media screen and (max-width: 1200px) {
  section#contacts {
    padding-bottom: 0px;
  }
  .card_org {
    grid-area: auto;
    padding: 20px;
    box-shadow: 4px 5px 8px rgba(88, 88, 88, 0.08);
  }
  .card_org-row-title {
    font-size: 18px;
    line-height: normal;
    min-width: 100px;
    color: #676f73;
  }
  .card_org-row-text {
    font-size: 18px;
    line-height: normal;
    text-align: right;
  }
  .card_org-row {
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
  }
}
</style>