<template>
  <section id="calc">
    <div class="container">
      <div class="calc-title">
        <h2>{{ block_title }}</h2>
        <h3>{{ title }}</h3>
      </div>
      <div class="calc__form" ref="calc">
        <div class="calc__inputs" ref="calc_items">
          <div class="calc__inputs-item-enabled">
            <label class="calc__inputs-item-title">Стоимость дома</label>
            <div
              class="prices-block-price cost"
              v-if="home_price_min == home_price_max"
            >
              {{ textHomePrice }} ₽
            </div>
            <div class="calc__inputs-item-inps" v-else>
              <input
                class="calc_inputs-input"
                type="text"
                placeholder="2 000 000"
                name="home_price"
                :value="textHomePrice"
                @keypress="onlyNumber"
                @change="homePriceChange"
              />
              <input
                class="calc_inputs-range"
                type="range"
                :min="home_price_min"
                :max="home_price_max"
                v-model="home_price"
              />
            </div>
          </div>
          <div class="calc__inputs-item">
            <label class="calc__inputs-item-title">Первоначальный взнос</label>
            <div class="calc__inputs-item-inps">
              <input
                class="calc_inputs-input"
                type="text"
                placeholder="2 000 000"
                name="first_contribution"
                :value="textContribution"
                @keypress="onlyNumber"
                @change="contributionChange"
              />
              <input
                class="calc_inputs-range"
                type="range"
                :min="calcMinContribution"
                :max="calcMaxContribution"
                v-model="first_contribution"
              />
              <div class="calc__inputs-item-hints">
                <div class="calc__inputs-item-hint min">
                  Мин. {{ first_contribution_min }}% (<span>{{
                    textMinContribution
                  }}</span>
                  р.)
                </div>
                <div class="calc__inputs-item-hint max">
                  Макс. {{ first_contribution_max }}% (<span>{{
                    textMaxContribution
                  }}</span>
                  р.)
                </div>
              </div>
            </div>
          </div>
          <div class="calc__inputs-item">
            <label class="calc__inputs-item-title">Тип ипотеки</label>
            <Select :selects="types" @change="changeRate" />
          </div>
          <div class="calc__inputs-item">
            <label class="calc__inputs-item-title">Срок кредита</label>
            <div class="calc__inputs-item-inps">
              <input
                class="calc_inputs-input term"
                type="text"
                placeholder="20"
                name="term_credit"
                v-model="term_credit"
                @keypress="onlyNumber"
              />
              <input
                class="calc_inputs-range"
                type="range"
                min="1"
                max="30"
                v-model="term_credit"
              />
              <div class="calc__inputs-item-hints term-hints">
                <div class="calc__inputs-item-hint">1 год</div>
                <div class="calc__inputs-item-hint">5 лет</div>
                <div class="calc__inputs-item-hint">10 лет</div>
                <div class="calc__inputs-item-hint">15 лет</div>
                <div class="calc__inputs-item-hint">20 лет</div>
                <div class="calc__inputs-item-hint">25 лет</div>
                <div class="calc__inputs-item-hint">30 лет</div>
              </div>
            </div>
          </div>
        </div>
        <div class="calc__info">
          <div class="calc__info-prices">
            <div class="calc__info-prices-block border">
              <div class="prices-block-title">Сумма кредита</div>
              <div class="prices-block-price total">
                <span>{{ sum_text }}</span> р.
              </div>
            </div>
            <div class="calc__info-prices-block">
              <div class="prices-block-title">Ежемесечный платеж</div>
              <div class="prices-block-price small">
                <span>{{ payment_text }}</span> р.
              </div>
            </div>
          </div>
          <div class="calc__info-rate">
            <div class="calc__info-prices-block rate">
              <div class="prices-block-title">Процентная ставка</div>
              <div class="prices-block-price">
                <span>{{ rate }}</span> %
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { numberWithSpaces } from "@/assets/js/helper.js";
import Select from "./Select.vue";
export default {
  name: "Calculator",
  components: {
    Select,
  },
  props: {
    color: {
      type: String,
      required: true,
      default: () => "grey",
    },
    first_contribution_min: {
      type: Number,
      default: () => 25,
    },
    first_contribution_max: {
      type: Number,
      default: () => 90,
    },
    home_price_min: {
      type: Number,
      default: () => 2000000,
    },
    home_price_max: {
      type: Number,
      default: () => 10000000,
    },
    types: {
      type: Array,
      default: () => [],
    },
    block_title: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      home_price: 0,
      home_price_text: "",
      first_contribution: 0,
      first_contribution_text: "",
      term_credit: 20,
      sum: 0,
      sum_text: "",
      payment: 0,
      payment_text: "",
      rate: 15,
    };
  },
  methods: {
    onlyNumber(e) {
      let keyCode = e.keyCode ? e.keyCode : e.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46 && keyCode != 32) {
        // 46 is dot
        e.preventDefault();
      }
    },
    homePriceChange(event) {
      const text_value = event.target.value;

      // Проверка на символы в поле
      const pattern = /((\d{1,})(\s{1,})?){1,}/;
      if (!pattern.test(text_value)) {
        this.home_price = this.home_price_min;
        event.target.value = this.textHomePrice;
        return;
      }

      let number_value = Number(text_value.replace(/ /g, ""));

      if (number_value < this.home_price_min) {
        number_value = this.home_price_min;
      }

      if (number_value > this.home_price_max) {
        number_value = this.home_price_max;
      }

      this.home_price = number_value;
      event.target.value = this.textHomePrice;
    },
    contributionChange(event) {
      const text_value = event.target.value;

      // Проверка на символы в поле
      const pattern = /((\d{1,})(\s{1,})?){1,}/;
      if (!pattern.test(text_value)) {
        this.first_contribution = this.calcMinContribution;
        event.target.value = this.textContribution;
        return;
      }

      let number_value = Number(text_value.replace(/ /g, ""));

      if (number_value < this.calcMinContribution) {
        number_value = this.calcMinContribution;
      }

      if (number_value > this.calcMaxContribution) {
        number_value = this.calcMaxContribution;
      }

      this.first_contribution = number_value;
      event.target.value = this.textContribution;
    },
    calcPayment() {
      const count_months = this.term_credit * 12,
        monthly_rate = this.rate / 100 / 12,
        annuity_ratio =
          (monthly_rate * Math.pow(1 + monthly_rate, count_months)) /
          (Math.pow(1 + monthly_rate, count_months) - 1);

      this.payment = Math.round(this.sum * annuity_ratio);
    },
    changeRate(index) {
      this.rate = this.types[index].percent;
    },
  },
  computed: {
    calcMinContribution() {
      return Math.round((this.home_price / 100) * this.first_contribution_min);
    },
    calcMaxContribution() {
      return Math.round((this.home_price / 100) * this.first_contribution_max);
    },
    textMinContribution() {
      return numberWithSpaces(
        Math.round((this.home_price / 100) * this.first_contribution_min)
      );
    },
    textMaxContribution() {
      return numberWithSpaces(
        Math.round((this.home_price / 100) * this.first_contribution_max)
      );
    },
    textHomePrice() {
      return numberWithSpaces(this.home_price);
    },
    textContribution() {
      return numberWithSpaces(this.first_contribution);
    },
  },
  watch: {
    types(newValue) {
      if (newValue.length > 0) {
        this.rate = newValue[0].percent;
      }
    },
    home_price(newValue) {
      this.first_contribution = this.calcMinContribution;
      this.sum = newValue - this.first_contribution;

      this.calcPayment();
    },
    first_contribution(newValue) {
      this.first_contribution_text = numberWithSpaces(newValue);
      this.sum = this.home_price - newValue;

      this.calcPayment();
    },
    term_credit(newValue) {
      // Проверка на символы в поле
      const pattern = /((\d{1,})(\s{1,})?){1,}/;
      if (!pattern.test(newValue)) {
        this.term_credit = 1;
        this.calcPayment();
        return;
      }

      if (newValue < 1) {
        newValue = 1;
      }

      if (newValue > 30) {
        newValue = 30;
      }

      this.term_credit = newValue;
      this.calcPayment();
    },
    sum(newValue) {
      this.sum_text = numberWithSpaces(newValue);
    },
    payment(newValue) {
      this.payment_text = numberWithSpaces(newValue);
    },
    home_price_min() {
      this.home_price = this.home_price_min;
      this.first_contribution = this.calcMinContribution;

      this.calcPayment();
    },
    rate() {
      this.calcPayment();
    },
  },
};
</script>
<style>
.calc-title {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.calc-title h3 {
  grid-area: 1 / 2 / 2 / 4;
}

.calc__form {
  margin-top: 40px;
  display: flex;
  background: var(--white);
  box-shadow: 4px 5px 8px 5px rgba(88, 88, 88, 0.08);
}

.calc__inputs {
  padding: 25px 30px 57px 25px;
  display: flex;
  flex-direction: column;
  gap: 65px;
  width: 70%;
}

.calc__inputs-item,
.calc__inputs-item-enabled {
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

.calc__inputs-item-title {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: 106%;
  max-width: 165px;
  width: 100%;
}

.calc__inputs-item-inps {
  position: relative;
  width: 100%;
  height: 100%;
}

.calc_inputs-input.price {
  border: none;
}

.calc_inputs-input {
  padding: 5px 25px;
  color: var(--main-color);
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  outline: none;
  border: 1px solid var(--grey);
  transition: 0.5s;
  width: 100%;
}

.calc_inputs-input:focus,
.calc_inputs-input:focus-visible,
.calc_inputs-input:not(:placeholder-shown) {
  border: 1px solid var(--main-color);
}

.calc_inputs-input:placeholder-shown,
.calc_inputs-input::placeholder {
  color: var(--grey);
}

.calc_inputs-range {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 0px;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: var(--grey);
  border-radius: 0px;
  border: 0px;
}

input[type="range"]::-webkit-slider-thumb {
  border: 0px;
  height: 8px;
  width: 8px;
  border-radius: 0px;
  background: var(--main-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.5px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--grey);
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: var(--grey);
  border-radius: 0px;
  border: 0px;
}

input[type="range"]::-moz-range-thumb {
  border: 0px;
  height: 8px;
  width: 8px;
  border-radius: 0px;
  background: var(--main-color);
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: var(--grey);
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: var(--grey);
  border: 0px;
  border-radius: 0px;
}

input[type="range"]::-ms-fill-upper {
  background: var(--grey);
  border: 0px;
  border-radius: 0px;
}

input[type="range"]::-ms-thumb {
  margin-top: 1px;
  border: 0px;
  height: 8px;
  width: 8px;
  border-radius: 0px;
  background: var(--main-color);
  cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower,
input[type="range"]:focus::-ms-fill-upper {
  background: var(--grey);
}

.calc__inputs-item-hints {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  top: calc(100% + 6px);
  width: 100%;
}

.calc__inputs-item-hint {
  color: var(--grey);
  font-size: 14px;
  font-weight: 600;
  line-height: 106%;
  min-width: 46px;
}

.term-hints .calc__inputs-item-hint:nth-child(2) {
  margin-left: -36px;
}

.term-hints .calc__inputs-item-hint:nth-child(6) {
  margin-right: -20px;
}

.calc__info {
  width: 30%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #f5ebd233;
}

.calc__info-prices {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calc__info-prices-block {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 13px 0px 0px;
}

.calc__info-prices-block.border {
  padding: 0px 0px 13px;
  border-bottom: 1px solid var(--grey);
}

.prices-block-title {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: 106%;
}

.prices-block-price {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  color: var(--main-color);
  /* 100% */
}
.prices-block-price.total {
  color: var(--golden);
}

.prices-block-price.cost {
  font-weight: normal;
}

.prices-block-price.small {
  font-size: 30px;
}

.calc__info-prices-block.rate {
  padding-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .calc-title {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .calc__form {
    margin-top: 35px;
    flex-direction: column;
    box-shadow: none;
    gap: 50px;
  }
  .calc__inputs {
    padding: 0;
    width: 100%;
    gap: 40px;
  }
  .calc__inputs-item,
  .calc__inputs-item-enabled {
    flex-direction: column;
    gap: 8px;
  }
  .calc_inputs-input {
    padding: 14px 18px;
  }
  .calc__inputs-item-title {
    max-width: none;
  }
  .calc__info {
    width: 100%;
  }
  .calc__inputs-item-hint:nth-child(2),
  .calc__inputs-item-hint:nth-child(3),
  .calc__inputs-item-hint:nth-child(5),
  .calc__inputs-item-hint:nth-child(6) {
    display: none;
  }
}
</style>
