<template>
  <TitleWithColumns>
    <template v-slot:title>
      <h1>{{ title }}</h1>
    </template>
    <div class="twc-list">
      <div class=""></div>
      <div class="twc-list-text">
        {{ main_banner?.text }}
      </div>
      <div class="main-btns">
        <Button color="white" @click="openPopup">Оставить заявку</Button>
      </div>
    </div>
  </TitleWithColumns>
</template>
<script>
import TitleWithColumns from "../UI/TitleWithColumns.vue";
import Button from "@/components/UI/Button.vue";

export default {
  components: {
    Button,
    TitleWithColumns,
  },
  props: {
    title: "",
    main_banner: {
      text: "",
    },
  },
  methods: {
    openPopup() {
      this.$store.commit("examplePopup", true);
      this.$store.commit("setVacancy", '');
    },
  },
};
</script>
<style scoped>
.container.main-container {
  display: flex;
  flex-direction: column;
}

.main-container .main-content {
  width: calc(100% - 430px);
  justify-content: flex-start;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 100px;
}
</style>
