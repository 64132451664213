<template>
    <div class="drop-container">
        <div class="drop-header" @click="toggleDropItem()">
            <div class="drop-header-pc">
                <div class="drop-header-block">
                    <p v-html="header"></p>
                    <p class="drop-salary" v-html="salary"></p>
                    <div :class="is_active ? 'active' : ''" class="drop-btn" ref="btn">
                        <span class="btn-line"></span>
                        <span class="btn-line second"></span>
                    </div>
                </div>
            </div>
            <div class="drop-header-mobile">
                <div class="drop-header-block mobile">
                    <p v-html="header"></p>
                    <div :class="is_active ? 'active' : ''" class="drop-btn" ref="btn">
                        <span class="btn-line"></span>
                        <span class="btn-line second"></span>
                    </div>
                </div>
                <div class="drop-header-block mobile">
                    <p class="drop-salary" v-html="salary"></p>
                </div>
            </div>
        </div>
        <div class="drop-item" ref="drop_item" :class="is_active ? 'active' : ''">
            <slot> </slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        is_toggle_drop: false,
        header: "",
        text: "",
        salary: "",
        index: "",
        is_active: "",
    },
    data() {
        return {
            is_drop: false,
            max_height: 0,
        };
    },
    methods: {
        toggleDropItem() {
            if (this.is_active) {
                this.$emit("close", this.index);
            } else {
                this.$emit("open", this.index);
            }
        },
        open() {
            const drop_item = this.$refs.drop_item;
            drop_item.style.maxHeight = this.max_height + 150 + "px";
        },
        close() {
            const drop_item = this.$refs.drop_item;
            drop_item.style.maxHeight = "";
        },
    },
    emit: ['open', 'close'],
    mounted() {
        const drop_item = this.$refs.drop_item;
        this.max_height = drop_item.scrollHeight;
    },
};
</script>
<style>
.drop-container {
    width: 100%;
}

.drop-container:not(:first-of-type) {
    margin-top: 30px;
}

.drop-header {
    box-shadow: 4px 5px 8px rgba(88, 88, 88, 0.08);
    display: block;
    width: 100%;
    gap: 36px;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--white);
    padding: 25px 25px 20px;
}

.drop-btn {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid var(--golden);
}

.drop-btn.active .btn-line {
    transform: rotate(-45deg);
}

.drop-btn.active .btn-line.second {
    transform: rotate(45deg);
}

.btn-line {
    transition: 0.4s;
    position: absolute;
    top: 50%;
    /* left: 50%; */
    width: 10px;
    transform: rotate(45deg) translate(-2px, 3px);
    border-top: 1px solid var(--golden);
}

.btn-line.second {
    transform: rotate(-45deg) translate(2px, 3px);
}

.drop-item {
    max-height: 0px;
    overflow: hidden;
    transition: 0.8s;
    column-gap: 50px;
    row-gap: 40px;
    padding: 0px 25px 0px 25px;
    position: relative;
    opacity: 0;
}

.drop-element:nth-child(5) {
    grid-column: span 2;
}

.drop-item.active {
    padding-top: 50px;
    padding-bottom: 100px;
    box-shadow: 4px 5px 8px rgba(88, 88, 88, 0.08);
    background-color: var(--white);
    opacity: 1;
}

.drop-element {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #585858;
    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 128.5%;
}

.drop-element ul {
    padding-left: 25px;
}

.drop-element span {
    color: #343434;
    font-family: Mont;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 124.5%;
}

.drop-header p {
    margin: 0px;
    color: #585858;
    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.drop-header p span {
    color: #343434;
    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.drop-header-mobile {
    display: none;
}

.drop-header-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.drop-header p.drop-salary {
    gap: 10px;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .drop-header-pc {
        display: none;
    }

    .drop-header-mobile {
        display: block;
    }

    .drop-header-block {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .drop-header {
        padding: 20px;
    }

    .drop-header p {
        font-weight: 700;
        color: #343434;
        font-family: Mont;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .drop-header p.drop-salary {
        gap: 8px;
        justify-content: space-between;
    }

    .drop-header p span {
        font-family: Mont;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .drop-item {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .drop-item.active {
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .drop-container:not(:first-of-type) {
        margin-top: 20px;
    }

    .drop-element span {
        font-size: 20px;
    }

    .drop-element-btn {
        display: none;
    }
}
</style>
