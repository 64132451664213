<template>
    <div class="select-container" v-if="selects.length > 0">
        <div class="select-header" @click="toggleSelect()">
            <p>
                {{ selected_name }} <span>{{ selected_rate }}</span>%
            </p>
            <div :class="is_active ? 'active' : ''" class="select-btn" ref="btn">
                <span class="select-line"></span>
                <span class="select-line second"></span>
            </div>
        </div>
        <div class="select-body" ref="select_body" :class="is_active ? 'active' : ''">
            <div class="select_body-item" v-for="(item, index) in selects" @click="selectItem(index)">
                {{ item.name }} <span>{{ item.percent }}</span>%
            </div>
        </div>
    </div>
</template>
<script>
import { array } from '@amcharts/amcharts5';

export default {
    name: "Select",
    props: {
        selects: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            is_active: false,
            selected_name: "",
            selected_rate: "",
        };
    },
    methods: {
        toggleSelect() {
            if (!this.is_active) {
                const drop_item = this.$refs.select_body;
                drop_item.style.maxHeight = drop_item.scrollHeight + 150 + "px";
            } else {
                const drop_item = this.$refs.select_body;
                drop_item.style.maxHeight = "";
            }
            this.is_active = !this.is_active;
        },
        selectItem(index) {
            this.selected_name = this.selects[index].name;
            this.selected_rate = this.selects[index].percent;
            this.is_active = !this.is_active;
            const drop_item = this.$refs.select_body;
            drop_item.style.maxHeight = "";
            this.$emit('change', index);
        },
    },
    mounted() {
        if (this.selects.length > 0) {
            this.selected_name = this.selects[0].name;
            this.selected_rate = this.selects[0].percent;
        }
    },
    watch: {
        selects(new_value) {
            if (new_value.length > 0) {
                this.selected_name = new_value[0].name;
                this.selected_rate = new_value[0].percent;
            }
        }
    },
    emits: ['change']
};
</script>
<style>
.city-drop-container:not(:first-of-type) {
    margin-top: 30px;
}

.city-drop-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    padding-bottom: 6px;
}

.city-drop-btn {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid var(--golden);
}

.city-drop-btn.active .city-drop-line {
    transform: rotate(-45deg);
}

.city-drop-btn.active .city-drop-line.second {
    transform: rotate(45deg);
}

.city-drop-line {
    transition: 0.4s;
    position: absolute;
    top: 50%;
    /* left: 50%; */
    width: 10px;
    transform: rotate(45deg) translate(-2px, 3px);
    border-top: 1px solid var(--golden);
}

.city-drop-line.second {
    transform: rotate(-45deg) translate(2px, 3px);
}

.city-drop-item {
    max-height: 0px;
    overflow: hidden;
    transition: 0.8s;
    opacity: 0;
}

.city-drop-item.active {
    opacity: 1;
}

.city-drop-header p {
    color: var(--grey);
    font-family: Mont;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    /* 125% */
}

.city-drop-item-city p {
    padding: 6px 0;
}

.select-container {
    position: relative;
    width: 100%;
}

.select-header {
    padding: 5px 25px;
    color: var(--main-color);
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    outline: none;
    border: 1px solid var(--grey);
    transition: 0.5s;
    width: 100%;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.select-header>* {
    color: var(--grey);
    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    /* 166.667% */
}

.select-btn {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    background-color: transparent;
}

.select-line {
    transition: 0.4s;
    position: absolute;
    top: 50%;
    /* left: 50%; */
    width: 15px;
    transform: rotate(45deg) translate(-4px, 5px);
    border-top: 1px solid var(--grey);
}

.select-line.second {
    transform: rotate(-45deg) translate(2px, 3px);
}

.select-btn.active .select-line {
    transform: rotate(-45deg);
}

.select-btn.active .select-line.second {
    transform: rotate(45deg);
}

.select-body {
    max-height: 0px;
    overflow: hidden;
    transition: 0.8s;
    opacity: 0;
    border-top: none;
    border-left: 1px solid var(--grey);
    border-right: 1px solid var(--grey);
    border-bottom: 1px solid var(--grey);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--white);
    z-index: 10;
}

.select-body.active {
    opacity: 1;
}

.select_body-item {
    padding: 5px 25px;
    color: var(--main-color);
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    outline: none;
    transition: 0.5s;
    width: 100%;
    cursor: pointer;
}

.select_body-item:hover {
    background-color: #f5ebd233;
    color: var(--golden);
}
</style>
