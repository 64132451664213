<template>
  <Main
    :title="page_data.title"
    :texts="page_data.data?.main_banner.texts"
    :img="page_data.data?.main_banner.img"
  />
  <Advantages :plus="page_data.data?.plus" />
  <History :history="page_data.data?.history" />
  <Team :team="page_data.data?.team" />
  <Serteficat :serteficate="page_data.data?.serteficate" />
  <Partner :partner="page_data.data?.partner" />
  <Reviews :review="page_data.data?.review" />
  <SectionOrder
    :title="page_data.data?.block_name"
    :form="page_data.data?.form"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";

import Reviews from "@/components/home/reviews.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import Main from "@/components/company/main.vue";
import Advantages from "@/components/company/advantages.vue";
import History from "@/components/company/history.vue";
import Team from "@/components/company/team.vue";
import Serteficat from "@/components/company/serteficat.vue";
import Partner from "@/components/partners/PartnerPartners.vue";

export default {
  name: "HomeView",
  components: {
    SectionOrder,
    Reviews,
    Main,
    Advantages,
    History,
    Team,
    Serteficat,
    Partner,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/company");
  },
};
</script>
