<template>
  <div class="mortgage">
    <MainMortgage
      :title="page_data.title"
      :texts="page_data.data?.main_banner.texts"
    />
    <Mortgage :mortgage="page_data.data?.mortgage" />
    <Calculator
      :block_title="page_data.data?.calculator?.block_title"
      :title="page_data.data?.calculator?.title"
      :home_price_min="page_data.data?.calculator.min_house_price"
      :home_price_max="page_data.data?.calculator.max_house_price"
      :first_contribution_min="page_data.data?.calculator.min_percent"
      :first_contribution_max="page_data.data?.calculator.max_percent"
      :types="page_data.data?.calculator.type"
    />
    <SectionOrder
      :title="page_data.data?.block_name"
      :form="page_data.data?.form"
    />
  </div>
</template>
<script>
import MainMortgage from "@/components/mortgage/MainMortgage.vue";
import Mortgage from "@/components/mortgage/Mortgage.vue";
import Calculator from "@/components/UI/Calculator.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "MortgageView",
  components: {
    MainMortgage,
    Mortgage,
    Calculator,
    SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/mortgage");
  },
};
</script>
<style></style>
