<template>
  <div class="partners">
    <PartnersMain
      :title="page_data.title"
      :btn="true"
      :btn_text="'Стать партнером'"
      :main_banner="page_data.data?.main_banner"
    />
    <PartnersCondition :condition="page_data.data?.condition" />
    <PartnersAdvantages :plus="page_data.data?.plus" />
    <PartnerPartners :partner="page_data.data?.partner" />
    <SectionOrder
      :title="page_data.data?.form.block_name"
      :form="page_data.data?.form"
      :is_partner="true"
    />
  </div>
</template>
<script>
import PartnersMain from "@/components/partners/PartnersMain.vue";
import PartnersCondition from "@/components/partners/PartnersCondition.vue";
import PartnersAdvantages from "@/components/partners/PartnersAdvantages.vue";
import PartnerPartners from "@/components/partners/PartnerPartners.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "PartnersView",
  components: {
    PartnersMain,
    PartnersCondition,
    PartnersAdvantages,
    PartnerPartners,
    SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/partners");
  },
};
</script>
