<template>
  <SectionTitle :title="title">
    <div class="section-order__wrapper">
      <h3 class="section-order-title" v-html="form?.form_title"></h3>
      <div class="section-order-content">
        <form class="section-order__form" ref="form">
          <div class="order__form-title">
            Заполните поля и с Вами<br />свяжутся в течение суток!
          </div>
          <div class="order__form-inputs">
            <div class="order__form-inputs-block">
              <input
                v-model="name"
                class="order__form-item"
                type="text"
                placeholder="Имя"
              />
              <span
                class="order__form-error order__form-error-name"
                :class="name_error != '' ? 'active' : ''"
                >{{ name_error }}</span
              >
            </div>
            <div class="order__form-inputs-block">
              <input
                v-model="phone"
                class="order__form-item"
                type="tel"
                placeholder="+7 (999) 999 99 99"
              />
              <span
                class="order__form-error order__form-error-phone"
                :class="phone_error != '' ? 'active' : ''"
                >{{ phone_error }}</span
              >
            </div>
            <div class="order__form-inputs-block">
              <input
                v-model="email"
                @input="checkField"
                class="order__form-item"
                type="email"
                placeholder="Почта"
              />
              <span
                class="order__form-error order__form-error-email"
                :class="email_error != '' ? 'active' : ''"
                >{{ email_error }}</span
              >
            </div>
            <div
              class="order__form-inputs-block resume"
              v-if="$router.currentRoute.value.path.indexOf('vacancy') > 0"
            >
              <input
                v-model="resume_link"
                @input="checkField"
                class="order__form-item"
                type="text"
                placeholder="Ссылка на резюме"
              />
              <span
                class="order__form-error order__form-error-resume_link"
                :class="resume_link_error != '' ? 'active' : ''"
                >{{ resume_link_error }}</span
              >
            </div>
          </div>
          <p class="order__form-policy">
            Нажимая кнопку, вы соглашаетесь <br />
            <a href="/privacy-policy" target="_blank"
              >с политикой конфеденциальности</a
            >
          </p>
          <Button color="orange" @click="send">Отправить</Button>
        </form>
        <div
          class="section-order__contacts"
          v-if="form?.is_show_contacts_and_map"
        >
          <div class="order__contacts-data">
            <a
              :href="'mailto:' + $store.state.site_info?.city?.email"
              class="order__contacts-item email"
              >{{ $store.state.site_info?.city?.email }}</a
            >
            <a
              :href="'tel:' + $store.state.site_info?.city?.phone"
              class="order__contacts-item phone"
              >{{ $store.state.site_info?.city?.phone }}</a
            >
            <div class="order__contacts-item email address">
              {{ $store.state.site_info?.city?.address }}
            </div>
          </div>
          <Map :height="'100%'" />
        </div>
      </div>
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "./SectionTitle.vue";
import Button from "./Button.vue";
import Map from "./Map.vue";

import orderApi from "@/assets/js/api/order.js";
import {
  validateUrl,
  validateEmail,
  replaceNumberForPaste,
  replaceToLatters,
} from "@/assets/js/helper.js";

export default {
  name: "SectionOrder",
  components: {
    SectionTitle,
    Map,
    Button,
  },
  props: {
    title: {
      type: String,
      default: () => "ЗАЯВКА НА ПРОЕКТ",
    },
    form: {
      type: Object,
      default: () => {
        return {
          form_title: "Заявка на строительство вашего идеального дома",
          is_show_contacts_and_map: true,
        };
      },
    },
    is_vacancy: {
      type: Boolean,
      default: () => false,
    },
    is_partner: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      // title: "Заявка на проект",
      name_error: "",
      phone_error: "",
      email_error: "",
      name: "",
      phone: "",
      email: "",
      resume_link: "",
      resume_link_error: "",
    };
  },
  methods: {
    async send() {
      this.validateFiled("name", this.name);
      this.validateFiled("phone", this.phone);
      this.validateFiled("email", this.email);
      this.validateFiled("resume_link", this.resume_link);

      const is_empty =
          this.name == "" || this.phone == "" || this.email == ""
            ? true
            : false,
        has_error =
          this.name_error || this.phone_error || this.email_error
            ? true
            : false,
        vacancy_valid =
          this.resume_link == "" ||
          (this.resume_link != "" && this.resume_link_error == "")
            ? true
            : false;

      if (!is_empty && !has_error && vacancy_valid) {
        const page = this.$route.path.replace("/", "");

        const data = {
          request_page: page != "" ? page : "home",
          type: "Заявка на проект",
          name: this.name,
          phone: this.phone,
          email: this.email,
        };

        if (this.is_vacancy) {
          data.type = "Заявка на вакансию";
          data.vacancy = this.vacancy;
          data.resume_link = this.resume_link;
        }

        if (this.is_partner) {
          data.type = "Заявка на партнёрство";
        }

        const result = await orderApi.create(data);

        if (result) {
          this.$refs.form.reset();
          this.$store.commit("resultSuccess", result.success);
          this.$store.commit("answerOpen", true);
        }
      }
    },
    validateFiled(field_name, value) {
      switch (field_name) {
        case "name":
          this.name_error = "";

          if (value == "") {
            this.name_error = "Обязательное поле!";
            return;
          }

          break;

        case "phone":
          this.phone_error = "";

          if (value == "") {
            this.phone_error = "Обязательное поле!";
            return;
          }

          if (value.length < 18) {
            this.phone_error = "Формат номера +7 (999) 999-99-99";
            return;
          }

          break;

        case "email":
          this.email_error = "";

          if (!validateEmail(value)) {
            this.email_error = "Некорректный email!";
            return;
          }

          break;

        case "resume_link":
          this.resume_link_error = "";

          if (value != "" && !validateUrl(value)) {
            this.resume_link_error = "Некорректная ссылка!";
          }

          break;
      }
    },
  },
  watch: {
    name(new_value) {
      this.validateFiled("name", new_value);
      this.name = replaceToLatters(new_value, true);
    },
    phone(new_value) {
      this.phone = replaceNumberForPaste(new_value);
      this.validateFiled("phone", new_value);
    },
    email(new_value) {
      this.validateFiled("email", new_value);
    },
    resume_link(new_value) {
      this.validateFiled("resume_link", new_value);
    },
  },
};
</script>
<style>
.section-order__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  grid-area: 1 / 2 / 2 / 4;
}

.section-order-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.section-order__form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.order__form-title {
  color: var(--main-color);
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
}

.order__form-inputs {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order__form-inputs-block {
  position: relative;
}

.order__form-error.active {
  transition: 0.5s;
  opacity: 1;
}

input:is([type="email"], [type="text"], [type="tel"]).order__form-item {
  color: var(--main-color);
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--golden);
  padding: 20px 0;
  width: 100%;
}

input:is(
    [type="email"],
    [type="text"],
    [type="tel"]
  ).order__form-item:placeholder-shown,
input:is(
    [type="email"],
    [type="text"],
    [type="tel"]
  ).order__form-item::placeholder {
  color: var(--grey);
}

.order__form-error {
  color: var(--red);
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  z-index: 10;
  width: 100%;
  text-align: center;
}

.order__form-policy {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.order__form-policy a {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-decoration-line: underline;
  transition: 0.5s;
}

.order__form-policy a:hover {
  color: var(--golden);
}

/* Контакты */
.section-order__contacts {
  display: flex;
  flex-direction: column;
  gap: 70px;
  justify-content: space-between;
}

.order__contacts-data {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.order__contacts-item {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  transition: 0.5s;
}

.order__contacts-item:not(.address):hover {
  color: var(--golden);
}

@media screen and (max-width: 1200px) {
  .section-order__contacts {
    display: none;
  }
  .section-order__wrapper {
    grid-area: auto;
    gap: 10px;
  }
  .section-order__form {
    width: 100%;
  }
  .mobile {
    display: none;
  }
  .order__form-policy,
  .order__form-policy a {
    font-size: 16px;
  }
  .section-order-content {
    grid-template-columns: 1fr;
  }
  .order__form-btn {
    margin: 0 auto;
  }
}
</style>
