<template>
  <div class="ready-houses">
    <OurObjectsMain :title="'Серийные проекты'" :type="'serial'" />
  </div>
</template>
<script>
import OurObjectsMain from "@/components/our-objects/OurObjectsMain.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    OurObjectsMain,
  },
  methods: {
    ...mapMutations({
      setLoading: 'page/setLoading'
    }),
  },
  mounted() {
    this.setLoading(false)
  }
};
</script>
