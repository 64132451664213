<template>
  <div class="imgTitleBlock" :class="reverse ? 'reverse' : ''">
    <div
      class="imgTitleBlock__pic"
      :class="
        $router.currentRoute.value.path.indexOf('stocks') > 0 ? 'stocks' : ''
      "
    >
      <img :src="img" alt="" />
    </div>
    <div class="imgTitleBlock__content">
      <h3 class="imgTitleBlock__content-title" v-html="title"></h3>
      <div class="imgTitleBlock__content-text" v-html="text"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImgTitleBlock",
  props: {
    reverse: false,
    img: "",
    title: "",
    text: "",
  },
};
</script>
<style>
.imgTitleBlock-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 180px;
}

.imgTitleBlock {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
@media screen and (min-width: 1201px) {
  .imgTitleBlock.reverse .imgTitleBlock__pic {
    grid-area: 1 / 2 / 2 / 4;
  }
  .imgTitleBlock.reverse .imgTitleBlock__content {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

.imgTitleBlock__pic {
  width: 100%;
  height: 100%;
  grid-column: 1 / span 2;
}

.imgTitleBlock__pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgTitleBlock__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.imgTitleBlock__content-text {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .imgTitleBlock {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .imgTitleBlock__pic,
  .imgTitleBlock__content {
    width: 100%;
    gap: 12px;
  }
  .imgTitleBlock-list {
    gap: 50px;
  }
  .imgTitleBlock__pic.stocks img {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
</style>
