<template>
  <SectionTitle :title="process?.block_title">
    <div class="custom-content">
      <div
        class="number-block-list"
        v-show="pc"
        :class="process?.processes ? 'stage' : ''"
      >
        <NumberBlock
          v-for="(item, index) in process?.processes"
          :index="index"
          :title="item.title"
          :text="item.text"
          :stage="true"
        />
      </div>
      <NumberDropList v-show="mobile" :tabs="process?.processes" />
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
import NumberBlock from "../UI/NumberBlock.vue";
import NumberDropList from "../UI/NumberDropList.vue";

export default {
  name: "IndividualProjectsCondition",
  components: {
    SectionTitle,
    NumberBlock,
    NumberDropList,
  },
  props: {
    process: {
      block_title: "",
      processes: [],
    },
  },
  data() {
    return {
      pc: true,
      mobile: false,
    };
  },
  methods: {
    change() {
      if (window.innerWidth > 1200) {
        this.pc = true;
        this.mobile = false;
      } else {
        this.pc = false;
        this.mobile = true;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.change);
  },
  destroyed() {
    window.removeEventListener("resize", this.change);
  },
  mounted() {
    if (window.innerWidth < 1200) {
      this.pc = false;
      this.mobile = true;
    }
    this.change;
  },
};
</script>
<style>
@media screen and (max-width: 1200px) {
  .custom-content .drop-list {
    padding-left: 38px;
  }
}
</style>
