<template>
  <TitleWithColumns>
    <template v-slot:title>
      <h1>{{ title }}</h1>
    </template>
    <div class="twc-list">
      <div class="twc-list-text">
        {{ main_banner?.text }}
      </div>
      <div class="twc-list-img">
        <img :src="main_banner?.img" alt="" />
      </div>
    </div>
  </TitleWithColumns>
</template>
<script>
import TitleWithColumns from "../UI/TitleWithColumns.vue";
export default {
  components: {
    TitleWithColumns,
  },
  props: {
    title: "",
    main_banner: {},
  },
};
</script>
<style>
.main-production {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;
}

.main-production p {
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 138.889% */
}
.twc-list-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1200px) {
  .twc-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .twc-list-img img {
    height: 200px;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
</style>
