<template>
  <Main :title="page_data.title" :main_banner="page_data.data?.main_banner" />
  <SectionTitle :title="page_data.data?.vacancy.block_title">
    <div class="vacancy-drop-list">
      <DropList
        :tabs="page_data.data?.vacancy.vacancies"
        :with_btns="true"
        :btns_function="openPopup"
      ></DropList>
    </div>
  </SectionTitle>
  <SectionOrder
    :title="page_data.data?.form.block_name"
    :form="page_data.data?.form"
    :is_vacancy="true"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";
import Main from "@/components/vacancy/Main.vue";
import SectionTitle from "@/components/UI/SectionTitle.vue";
import DropList from "@/components/base/DropList.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";

export default {
  name: "HomeView",
  components: {
    Main,
    SectionOrder,
    SectionTitle,
    DropList,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
    openPopup(title) {
      this.$store.commit("examplePopup", true);
      this.$store.commit("setVacancy", title);
    },
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/vacancy");
  },
};
</script>

<style>
.vacancy-drop-list {
  grid-area: 1 / 2 / 2 / 4;
}
@media screen and (max-width: 1200px) {
  .vacancy-drop-list {
    grid-area: auto;
  }
}
</style>
