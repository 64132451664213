<template>
  <SectionTitleRow
    :title="production?.block_title"
    v-if="production?.productions.length > 0"
  >
    <div class="capacity-columns">
      <div class="capacity-item" v-if="production?.productions[0]">
        <div
          class="capacity-item-text"
          v-html="production?.productions[0].text"
        ></div>
        <p class="capacity-number">1</p>
      </div>
      <div class="capacity-item" v-if="production?.productions[1]">
        <div
          class="capacity-item-text"
          v-html="production?.productions[1].text"
        ></div>
        <p class="capacity-number">2</p>
      </div>
      <div class="capacity-item" v-if="production?.productions[2]">
        <div
          class="capacity-item-text"
          v-html="production?.productions[2].text"
        ></div>
        <p class="capacity-number">3</p>
      </div>
      <div class="capacity-item" v-if="production?.productions[3]">
        <div
          class="capacity-item-text"
          v-html="production?.productions[3].text"
        ></div>
        <p class="capacity-number">4</p>
      </div>
    </div>
  </SectionTitleRow>
</template>
<script>
import SectionTitleRow from "../UI/SectionTitleRow.vue";
export default {
  components: {
    SectionTitleRow,
  },
  props: {
    production: {
      block_title: "",
      productions: [],
    },
  },
};
</script>
<style>
.capacity-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 70px;
}

.capacity-item {
  display: flex;
}
.capacity-item:nth-child(3) {
  grid-area: 2 / 2 / 2 / 3;
}
.capacity-item:nth-child(4) {
  grid-area: 2 / 3 / 2 / 3;
}

.capacity-item p {
  color: #343434;
  font-family: Mont;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.capacity-item span {
  color: #343434;
  font-family: Mont;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

p.capacity-number {
  color: #f5ebd2;
  font-family: Mont;
  font-size: 200px;
  font-style: normal;
  font-weight: 800;
  line-height: 125px;
  z-index: -1;
  transform: translateX(-96px);
}

.production-capacity-title {
  margin-bottom: 42px;
}
@media screen and (max-width: 1200px) {
  .capacity-columns {
    grid-template-columns: repeat(2, 1fr);
  }
  .capacity-item:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .capacity-item:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }
  p.capacity-number {
    font-size: 160px;
    transform: translateX(-56px);
  }
}
@media screen and (max-width: 800px) {
  .capacity-columns {
    grid-template-columns: repeat(1, 1fr);
    gap: 75px;
  }
  .capacity-item:nth-child(3) {
    grid-area: 3 / 1 / 4 / 2;
  }
  .capacity-item:nth-child(4) {
    grid-area: 4 / 1 / 5 / 2;
  }
  .capacity-item {
    position: relative;
  }
  p.capacity-number {
    position: absolute;
    top: -20px;
    right: 10px;
    transform: translate(0);
  }
}
</style>
