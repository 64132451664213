<template>
  <SectionTitle :title="control?.block_title">
    <div class="custom-content">
      <div class="custom-content-wrapper">
        <h3>{{ control?.title }}</h3>
        <div class="number-block-list">
          <NumberBlock
            v-for="(item, index) in control?.controls"
            :index="index"
            :text="item.text"
          />
        </div>
      </div>
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
import NumberBlock from "../UI/NumberBlock.vue";
export default {
  name: "InvestmentMain",
  components: {
    SectionTitle,
    NumberBlock,
  },
  props: {
    control: {
      block_title: "",
      controls: [],
      title: "",
    },
  },
};
</script>
<style>
.custom-content-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
<style>
.custom-content-wrapper .number-block-list .number-block .number-block-number {
  top: calc(24px + 10px);
}

.custom-content-wrapper
  .number-block-list
  .number-block:first-of-type
  .number-block-number {
  top: 4px;
}
</style>
