import Api from "./index.js";

class ProjectsApi extends Api {
    async get (filters = {}, pagination = {}, city) {

        const default_city = this.params.site_info?.city?.id;

        if (!city) {
            city = default_city;
        }

        const default_filters = {
            type: 'objects',
        }

        filters = Object.assign(default_filters, filters);

        const default_pagination = {
            page: 1,
            posts_per_page: 10,
        }

        pagination = Object.assign(default_pagination, pagination);

        const data = {
            city,
            filters,
            pagination
        }

        const result = await this.send('projects', data, 'POST');

        return result?.data;
    }

    async getGraph () {
        try {
            const result = await this.send('graph');
    
            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default new ProjectsApi();