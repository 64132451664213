<template>
  <SectionTitle :title="investment_conditions?.block_title">
    <div class="section-content">
      <h3>{{ investment_conditions?.title }}</h3>
      <DropList :tabs="investment_conditions?.tabs" />
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
import DropList from "../base/DropList.vue";

export default {
  name: "InvestmentImgSection",
  components: {
    SectionTitle,
    DropList,
  },
  props: {
    investment_conditions: {
      block_title: "",
      title: "",
      tabs: [],
    },
  },
  data() {
    return {
      sect_bg: require("@/assets/img/main.jpg"),
    };
  },
};
</script>
<style>
.sect-img {
  padding-bottom: 0;
  /* padding-top: 180px; */
  /* margin-bottom: 180px; */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.sect-img h2,
.sect-img h3 {
  /* color: var(--white); */
}
.section-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-area: 1 / 2 / 2 / 4;
  gap: 20px;
}
.section-content .drop-list,
.mortgage-content .drop-list {
  grid-column: 1 / span 2;
}
@media screen and (max-width: 1200px) {
  .section-content {
    grid-area: auto;
  }
}
</style>
