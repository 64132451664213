<template>
  <SectionTitle
    :title="mortgage?.block_title"
    :link="link"
    :color_btn="color_btn"
    :btn_text="btn_text"
  >
    <div class="mortgage-content">
      <h3 class="mortgage-title">{{ mortgage?.title }}</h3>
      <DropList :tabs="mortgage?.tabs" />
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "../UI/SectionTitle.vue";
import DropList from "@/components/base/DropList.vue";
import Button from "../UI/Button.vue";

export default {
  name: "Mortgage",
  components: {
    SectionTitle,
    DropList,
    Button,
  },
  props: {
    mortgage: {
      block_title: "",
      tabs: [],
      title: "",
    },
  },
};
</script>
<style>
.mortgage-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-area: 1 / 2 / 2 / 4;
}

@media screen and (max-width: 1200px) {
  .mortgage-content {
    grid-area: auto;
    gap: 25px;
  }
}
</style>
