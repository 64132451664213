<template>
  <TitleWithColumns>
    <template v-slot:title>
      <h1>{{ title }}</h1>
    </template>
    <div class="twc-list" :class="btn ? 'with-btn' : ''" v-if="btn">
      <div class="twc-list-text">{{ text }}</div>
      <Button :color="'white'">Оставить заявку</Button>
    </div>
    <div class="twc-list" :class="pic ? 'with-pic' : ''" v-if="pic">
      <div class="twc-list-text">{{ text }}</div>
      <div class="twc-list-img">
        <img :src="require('@/assets/img/stocks.jpg')" alt="" />
      </div>
    </div>
    <div class="twc-list" v-if="!btn && !pic">
      <div class=""></div>
      <div class="twc-list-text" v-for="item in texts">{{ item.text }}</div>
    </div>
  </TitleWithColumns>
  <div class="full-size-img">
    <img class="baner" :src="img" alt="" />
  </div>
</template>
<script>
import TitleWithColumns from "@/components/UI/TitleWithColumns.vue";

export default {
  components: {
    TitleWithColumns,
  },
  props: {
    title: "",
    texts: [],
    img: "",
  },
};
</script>