<template>
  <div class="our-objects">
    <OurObjectsMain :title="'Наши объекты'" :type="'objects'" />
  </div>
</template>
<script>
import OurObjectsMain from "@/components/our-objects/OurObjectsMain.vue";
import { mapMutations } from "vuex";
export default {
  name: "OurObjectView",
  components: {
    OurObjectsMain,
  },
  methods: {
    ...mapMutations({
      setLoading: "page/setLoading",
    }),
  },
  mounted() {
    this.setLoading(false);
  },
};
</script>
