<template>
  <div class="serial-projects">
    <MainIndividualProjects
      :title="page_data.title"
      :main_banner="page_data.data?.main_banner"
      :btn="true"
    />
    <IndividualProjectsAdvantages :plus="page_data.data?.plus" />
    <GridExamples
      :completes="page_data.data?.completes"
      :serial_title="page_data.data?.serial_title"
      :link="'/projects/'"
    />
    <SectionOrder
      :title="page_data.data?.block_name"
      :form="page_data.data?.form"
    />
  </div>
</template>
<script>
import MainIndividualProjects from "@/components/Individual-projects/MainIndividualProjects.vue";
import IndividualProjectsAdvantages from "@/components/Individual-projects/IndividualProjectsAdvantages.vue";
import GridExamples from "@/components/UI/GridExamples.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "SerialProjectsView",
  components: {
    MainIndividualProjects,
    IndividualProjectsAdvantages,
    GridExamples,
    SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("serial_projects");
  },
};
</script>
<style></style>
