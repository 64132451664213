import Api from "@/assets/js/api/index.js";

class OrderApi extends Api {
  async create(data) {

    if ( !data.request_page || !data.type || !data.email || !data.name || !data.phone ) {
        console.error('Не заполнены обязательные данные.');
        return false;
    }

    try {
        const result = await this.send('createOrder', data, 'post');

        return result?.data;
    } catch (error) {
        console.error(error);
        return false;
    }
  }
}

export default new OrderApi();
