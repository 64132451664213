<template>
  <SectionTitle :title="serteficate?.block_title">
    <SliderSertificat
      :swiper_id="'sertificate'"
      :slidesPerView="2"
      :certificates="serteficate?.certificates"
    />
  </SectionTitle>
</template>
<script>
import SliderSertificat from "../UI/SliderSertificat.vue";
import SectionTitle from "../UI/SectionTitle.vue";

export default {
  components: {
    SliderSertificat,
    SectionTitle,
  },
  props: {
    serteficate: {
      block_title: "",
      certificates: [],
    },
  },
};
</script>
