<template>
  <div :class="'popup ' + ($store.state[type] ? 'active' : '')">
    <div class="popup-bg" @click="popupClose()"></div>
    <div class="popup-container">
      <div class="popup-header">
        <slot name="header"> </slot>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          @click="popupClose()"
        >
          <path
            d="M20.3125 5.6875L5.6875 20.3125"
            stroke="#C5C5C5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.3125 20.3125L5.6875 5.6875"
            stroke="#C5C5C5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="popup-content">
        <slot> </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: "",
  },
  methods: {
    popupClose() {
      this.$store.commit(this.type, false);
    },
  },
};
</script>
<style>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100lvh;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup.active {
  opacity: 1;
  pointer-events: all;
}

.popup-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
}

.popup-container {
  position: relative;
  background-color: var(--white);
  height: auto;
  z-index: 100;
  padding: 60px 130px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: auto;
}

.popup-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.popup-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.popup-header svg {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  width: 26px;
  height: 26px;
}
.popup-header svg path {
  transition: 0.5s;
}
.popup-header svg:hover path {
  stroke: var(--golden);
}
.popup-content p {
  text-align: center;
}
@media screen and (max-width: 800px) {
  .popup-container {
    padding: 50px 20px;
    width: 100%;
  }
}
</style>
