<template>
  <section v-if="title">
    <div class="container section-wrapper">
      <div class="section-title">
        <h2 v-html="title"></h2>
        <Button :color="color_btn" v-if="link" :link="link">{{
          btn_text
        }}</Button>
        <!-- Подробнее об условиях → -->
      </div>
      <slot></slot>
      <div class="mobile-btn">
        <Button :color="color_btn" v-if="link" :link="link">{{
          btn_text
        }}</Button>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "./Button.vue";

export default {
  name: "SectionTitle",
  components: {
    Button,
  },
  props: {
    title: "",
    link: "",
    color_btn: "",
    btn_text: "",
    display: "block",
  },
  data() {
    return {
      is_show: true,
    };
  },
};
</script>
<style>
.section-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.section-title.serial {
  min-width: 100%;
}
.section-title h2 {
  min-height: 80px;
}
.section-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.section-title.with-btn {
  justify-content: space-between;
}

.mobile-btn {
  display: none;
}
@media screen and (max-width: 1200px) {
  .section-title h2 {
    min-height: auto;
  }
  .section-wrapper {
    grid-template-columns: 1fr;
    gap: 35px;
  }
  .section-title {
    min-width: auto;
    max-width: none;
    width: 100%;
    gap: 35px;
  }
  .section-title img {
    display: none;
  }
  .section-title .button-link {
    display: none;
  }
  .mobile-btn {
    display: block;
  }
}
</style>
