<template>
	<section id="mainSocialObjects">
		<div class="container">
			<div class="mainSocialObjects-title">
				<h1 v-html="title"></h1>
			</div>
			<div class="imgTitleBlock-list">
				<ImgTitleBlocks 
					v-for="(item, index) in stocks" 
					:reverse="((index + 1) % 2 === 0)" 
					:title="item.title" 
					:img="item.img"
					:text="item.content" 
				/>
				<div class="imgTitleBlock-list-btns">
					<Button 
						v-if="is_can_more" 
						color="white" 
						@click="getMoreStocks"
					>
						Загрузить ещё
					</Button>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import postsApi from '@/assets/js/api/posts.js'

import ImgTitleBlocks from '@/components/UI/ImgTitleBlocks.vue'
import Button from '@/components/UI/Button.vue'

export default {
	name: 'MainStocks',
	components: {
		ImgTitleBlocks,
		Button
	},
	props: {
		title: {
			type: String,
			default: () => 'Акции'
		}
	},
	data() {
		return {
			page: 1,
			stocks: [],
			is_can_more: false,
		}
	},
	methods: {
		async getMoreStocks() {
			const result = await postsApi.getMore('stocks', {
				page: this.page
			});

			if (result) {
				this.stocks.push(...result.posts);
				this.page++;
				this.is_can_more = result.is_can_more;
			}
		}
	},
	async created() {
		await this.getMoreStocks();
	}
}
</script>
<style>
	.imgTitleBlock-list-btns {
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>