<template>
  <SectionTitle :title="plus?.block_title">
    <div class="number-block-list">
      <NumberBlock
        v-show="pc"
        v-for="(item, index) in plus?.pluses"
        :text="item.text"
        :title="item.title"
        :index="index"
      />
      <NumberDropList v-show="mobile" :tabs="plus?.pluses" />
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "@/components/UI/SectionTitle.vue";
import NumberBlock from "../UI/NumberBlock.vue";
import NumberDropList from "../UI/NumberDropList.vue";

export default {
  components: {
    SectionTitle,
    NumberBlock,
    NumberDropList,
  },
  props: {
    plus: {
      block_title: "",
      pluses: [],
    },
  },
  data() {
    return {
      pc: true,
      mobile: false,
    };
  },
  methods: {
    change() {
      if (window.innerWidth > 1200) {
        this.pc = true;
        this.mobile = false;
      } else {
        this.pc = false;
        this.mobile = true;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.change);
  },
  destroyed() {
    window.removeEventListener("resize", this.change);
  },
  mounted() {
    this.change;
  },
};
</script>
<style>
.advantage-item {
  display: flex;
  flex-direction: row;
  gap: 13px;
  padding: 30px 0px;
  border-bottom: 1px solid #c9c9c9;
}

.advantage-item:first-of-type {
  padding-top: 0px;
}

.advantage-number {
  padding-top: 11px;
  color: #cd9c1c;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.advantage-title {
  color: #343434;
  font-family: Mont;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.advantage-desc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.advantage-desc p {
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.full-size-img {
  width: 100%;
  margin-bottom: 180px;
}
.full-size-img img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .full-size-img img {
    height: 200px;
  }
  .full-size-img {
    margin-bottom: 80px;
  }
}
</style>
