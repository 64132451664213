<template>
  <div class="app-container">
    <Preloader />
    <Header />
    <CitiesList :cities_list="cities_list" />
    <HeaderMenu />
    <main>
      <router-view />
    </main>
    <Footer />
    <ExamplesPopup />
    <SocialPanel />
    <OrderAnswer/>
  </div>
</template>

<script>
import Preloader from "@/components/base/Preloader.vue";
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/UI/HeaderMenu.vue";
import Footer from "@/components/Footer.vue";
import ExamplesPopup from "@/components/examples/ExamplesPopup.vue";
import CitiesList from "./components/UI/CitiesList.vue";
import SocialPanel from "./components/UI/SocialPanel.vue";
import OrderAnswer from "./components/UI/OrderAnswer.vue";

export default {
  name: "App",
  components: {
    Preloader,
    Header,
    HeaderMenu,
    Footer,
    ExamplesPopup,
    CitiesList,
    SocialPanel,
    OrderAnswer
  },
  data() {
    return {
      cities_list: [
        {
          id: 1,
          name: "Чита",
        },
        {
          id: 2,
          name: "Хабаровск",
        },
      ],
    };
  },
};
</script>

<style>
@font-face {
  font-family: "Mont";
  src: url("./assets/fonts/Mont-Regular.eot");
  src: local("Mont Regular"), local("Mont-Regular"),
    url("./assets/fonts/Mont-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Mont-Regular.woff2") format("woff2"),
    url("./assets/fonts/Mont-Regular.woff") format("woff"),
    url("./assets/fonts/Mont-Regular.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Mont";
  src: url("./assets/fonts/Mont-SemiBold.eot");
  src: local("Mont SemiBold"), local("Mont-SemiBold"),
    url("./assets/fonts/Mont-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Mont-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Mont-SemiBold.woff") format("woff"),
    url("./assets/fonts/Mont-SemiBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

:root {
  --main-color: #343434;
  --golden: #cd9c1c;
  --grey: #585858;
  --red: #cd461c;
  --white: #fff;
  --lightgrey: #c9c9c9;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Mont";
  text-decoration: none;
  margin: 0;
  padding: 0;
}

h1 {
  color: var(--main-color);
  font-family: Mont;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-transform: uppercase;
  margin-bottom: 40px;
}

h2 {
  color: var(--golden);
  font-family: Mont;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;
}

h3 {
  color: var(--main-color);
  font-family: Mont;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  min-height: 80px;
}

#app {
  height: 100vh;
  font-family: Mont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

main {
  margin-top: 118px;
}

.container {
  max-width: 1740px;
  padding: 0 120px;
  margin: 0 auto;
  width: 100%;
}

.w50 {
  width: 50%;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: 28px;
    line-height: 30px; /* 107.143% */
    margin-bottom: 20px;
  }
  .container {
    padding: 0 20px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px; /* 125% */
    min-height: auto;
  }
  .w50 {
    width: 100%;
  }
}
@media screen and (min-width: 1201px) {
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(205, 156, 28, 1) #dfe9eb;
  }

  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    height: 2px;
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background-color: #c9c9c9;
  }

  *::-webkit-scrollbar-track:hover {
    background-color: #c9c9c9;
  }

  *::-webkit-scrollbar-track:active {
    background-color: #c9c9c9;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(205, 156, 28, 0.6);
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: rgba(205, 156, 28, 1);
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: var(--golden);
  }
}
</style>
