<template>
  <SectionTitle
    :title="stock?.block_title"
    :link="'stocks'"
    :color_btn="'grey'"
    :btn_text="'Перейти к акциям →'"
  >
    <div class="baner">
      <img :src="stock?.img" alt="" />
    </div>
  </SectionTitle>
</template>
<script>
import SectionTitle from "./SectionTitle.vue";
export default {
  name: "Banner",
  components: {
    SectionTitle,
  },
  props: {
    stock: {
      block_title: "",
      img: "",
    },
  },
};
</script>
<style>
.baner {
  width: 100%;
  height: auto;
  grid-area: 1 / 2 / 2 / 4;
}

.baner img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1200px) {
  .baner img {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .baner {
    grid-area: auto;
  }
}
</style>
