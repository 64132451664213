<template>
  <section id="contact-map">
    <div class="container">
      <Map v-if="is_mob == false" :height="'500px'" />
      <Map v-else-if="is_mob == true" :height="'300px'" />
    </div>
  </section>
</template>
<script>
import Map from "../UI/Map.vue";

export default {
  name: "ContactsMap",
  components: {
    Map,
  },
  data() {
    return {
        is_mob: false
    }
  },
  methods: {
    isMobile() {
        if(window.innerWidth > 1200) {
            this.is_mob = false
        }
        else {
            this.is_mob = true
        }
    }
  },
  mounted() {
    this.isMobile()
  }
};
</script>
<style>
#contact-map > * {
  min-height: 500px;
}
@media screen and (max-width: 1200px) {
    #contact-map > * {
      min-height: auto;
    }
}
</style>