<template>
  <section>
    <div class="container">
      <AddressBlock
        v-for="item in cities"
        :title="item.title"
        :email="item?.data.email"
        :phone="item?.data.phone"
        :address="item?.data.address"
        :work_time="item?.data.work_time"
      />
    </div>
  </section>
</template>
<script>
import AddressBlock from "../UI/AddressBlock.vue";

export default {
  name: "ContactsAddress",
  components: {
    AddressBlock,
  },
  props: {
    cities: [],
  },
};
</script>