<template>
    <Form url = 'http://api.sozdania-saitov.ru/v1/methods/request' method = 'POST'>
        <input type="text" placeholder="Имя">
        <input type="text" placeholder="Телефон">
        <button>Отправить</button>
    </Form>
</template>
<script>
import Form from '@/components/base/Form.vue';

export default {
    components: {
        Form,
    },
}
</script>
<style>

</style>