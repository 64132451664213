<template>
  <SectionTitleRow
    class="only-pc"
    :title="processes_block_title"
    :subtitle="processes_title"
  >
    <div class="process-content">
      <div class="left-column">
        <div
          class="process-item"
          ref="proccess_item"
          v-for="(item, index) in processes_left"
          @mouseover="changeInfo(item, index)"
        >
          <div class="process-item-title">
            <div class="process-item-line" ref="line"></div>
            {{ item.title }}
          </div>
          {{ item.text }}
        </div>
      </div>
      <div class="process-img">
        <img :src="process_img" alt="" />
        <svg
          width="327"
          height="117"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="title_img && text_img"
        >
          <path d="M326.5 1L264.5 92H1.5" stroke="#585858" />
          <text y="85" x="0" class="process-name">
            {{ title_img }}
          </text>
          <text y="110" x="0" class="process-post">
            {{ text_img }}
          </text>
        </svg>
      </div>
      <div class="right-column">
        <div
          class="process-item"
          ref="proccess_item"
          v-for="(item, index) in processes_right"
          @mouseover="changeInfo(item, index + processes_left.length)"
        >
          <div class="process-item-title">
            <div class="process-item-line" ref="line"></div>
            <span>{{ item.title }}</span>
          </div>
          {{ item.text }}
        </div>
      </div>
    </div>
  </SectionTitleRow>
</template>
<script>
import Button from "@/components/UI/Button.vue";
import SectionTitleRow from "../UI/SectionTitleRow.vue";
export default {
  components: {
    Button,
    SectionTitleRow,
  },
  props: {
    processes_block_title: "",
    processes_title: "",
    processes_left: [],
    processes_right: [],
  },
  data() {
    return {
      title_img: "",
      text_img: "",
      process_img: "",
    };
  },
  methods: {
    changeInfo(item, index) {
      this.title_img = item.title_img;
      this.text_img = item.text_img;
      this.process_img = item.img;

      this.$refs.line.forEach((el) => {
        el.style.width = 0;
      });

      const block = this.$refs.proccess_item[index];
      const line = this.$refs.line[index];
      const block_w = block.clientWidth;
      let container_w = document.querySelector(".process-content").clientWidth;
      let img_w = document.querySelector(".process-img").clientWidth;
      line.style.width = container_w - block_w - 30 * 2 - img_w + "px";
      // line.style.width = block_w + 45 + "px";
    },
  },
  watch: {
    processes_left(new_value) {
      if (new_value.length > 0) {
        this.process_img = new_value[0].img;
        this.title_img = new_value[0].title_img;
        this.text_img = new_value[0].text_img;
      }
    },
  },
};
</script>
<style>
.process {
  display: flex;
  flex-direction: column;
  gap: 105px;
}

.process-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.right-column,
.left-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.process-img {
  position: relative;
  width: 100%;
  height: 500px;
  background-color: var(--white);
}

.process-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.process-item {
  color: #585858;
  font-family: Mont;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: default;
}
.left-column .process-item-title .process-item-line {
  position: absolute;
  height: 1px;
  background-color: var(--main-color);
  width: 0px;
  left: calc(100% + 15px);
  top: 50%;
  transition: 0.5s;
  z-index: -10;
}

.right-column .process-item {
  align-items: flex-end;
  text-align: right;
}
.right-column .process-item-title .process-item-line {
  position: absolute;
  height: 1px;
  background-color: var(--main-color);
  width: 0px;
  right: calc(100% + 15px);
  top: 50%;
  transition: 0.5s;
  z-index: -10;
}

.process-item-title {
  position: relative;
  color: #343434;
  font-family: Mont;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 124.5%;
  width: fit-content;
  pointer-events: none;
}

.process-name {
  fill: #343434;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 124.5%;
}

.process-post {
  fill: #585858;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.5%;
}

.process-img img {
  margin-bottom: -8px;
  pointer-events: none;
}
@media screen and (max-width: 1200px) {
  .only-pc {
    display: none;
  }
}
</style>
