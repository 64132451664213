<template>
  <div class="address-block">
    <h2>{{ title }}</h2>
    <div class="address__info">
      <div class="address__info-contact">
        <a :href="'mailto:' + email">{{ email }}</a>
        <a :href="'tel:' + phone">{{ phone }}</a>
      </div>
      <div class="address__info-place">
        <div class="address__info-address">{{ address }}</div>
        <div class="address__info-schedule" v-html="work_time"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddressBlock",
  props: {
    title: "",
    email: "",
    phone: "",
    address: "",
    work_time: "",
  },
};
</script>
<style>
.address-block {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.address-block:not(:first-of-type) {
  margin-top: 100px;
}

.address-block h2 {
  font-size: 50px;
}

.address__info {
  display: flex;
  justify-content: space-between;
}

.address__info-contact {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.address__info-contact a {
  color: var(--main-color);
  font-size: 28px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  transition: 0.5s;
}

.address__info-contact a:hover {
  color: var(--golden);
}

.address__info-place {
  display: flex;
  flex-direction: column;
}

.address__info-address,
.address__info-schedule {
  max-width: 450px;
  color: var(--lightgrey);
  text-align: right;
  font-size: 30px;
  font-weight: 600;
  line-height: 142%;
  letter-spacing: 0.8px;
}
@media screen and (max-width: 1200px) {
  .address-block {
    gap: 35px;
  }
  .address__info-contact {
    gap: 20px;
  }
  .address-block h2 {
    font-size: 22px;
    line-height: normal;
  }
  .address__info-contact a {
    font-size: 18px;
    font-style: normal;
  }
  .address__info {
    flex-direction: column;
    gap: 30px;
  }
  .address__info-address,
  .address__info-schedule {
    color: #676f73;
    font-size: 18px;
    line-height: 142%; /* 25.56px */
    text-align: left;
  }
}
</style>
