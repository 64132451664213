<template>
  <footer>
    <div class="container footer_wraper">
      <div class="footer_up">
        <div class="footer-logo">
          <a href="/"><img :src="$store.state.site_info?.info?.footer_logo ?? footer?.logo" alt="" /></a>
        </div>
        <ul class="footer-menu">
          <div
            class="footer-menu-column"
            v-for="menu_group in $store.state.site_info?.menu"
          >
            <li class="footer-menu-item" v-for="menu_item in menu_group">
              <a :href="menu_item?.link" class="footer-menu-link">{{
                menu_item?.title
              }}</a>
            </li>
          </div>
        </ul>
        <div class="footer-contact_info">
          <a
            :href="'tel:' + $store.state.site_info?.city?.phone"
            class="contact_info-item phone"
            v-html="$store.state.site_info?.city?.phone"
          ></a>
          <div
            class="contact_info-item city"
            v-html="$store.state.site_info?.city?.name"
          ></div>
          <div
            class="contact_info-item address"
            v-html="$store.state.site_info?.city?.address"
          ></div>
          <div
            class="contact_info-item schedule"
            v-html="$store.state.site_info?.city?.work_time"
          ></div>
        </div>
      </div>
      <div class="footer_bottom">
        <div class="footer_bottom-item">© {{ footer?.year }}</div>
        <a :href="footer?.policy_link" target="_blank" class="footer_bottom-item">{{
          footer?.policy
        }}</a>
        <div class="footer_bottom-item devilopers">
          <div class="footer_bottom-item">Разработка сайта</div>
          <a href="https://your-startup.space/" target="_blank"><img :src="footer.ys_logo" alt="" /></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      footer: {
        logo: require("@/assets/img/logo_footer.png"),
        ys_logo: require("@/assets/img/logo_YourStartup.svg"),
        year: 2023,
        policy: "Политика конфидециальности",
        policy_link: "/privacy-policy",
      },
    };
  },
};
</script>

<style>
.container.footer_wraper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 50px 20px 20px;
}

footer {
  background: var(--main-color);
  width: 100%;
}

.footer_up {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.footer-logo {
  width: 105px;
  height: auto;
  align-self: flex-start;
}

.footer-logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.footer-menu {
  display: flex;
  gap: 80px;
  list-style-type: none;
}

.footer-menu-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-menu-link {
  text-decoration: none;
  color: var(--lightgrey);
  font-size: 18px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 100%;
}

.footer-contact_info {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.contact_info-item span {
  color: var(--golden);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  /* 138.889% */
}

.contact_info-item {
  text-decoration: none;
  color: var(--lightgrey);
  font-size: 18px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 100%;
  max-width: 270px;
}

.footer_bottom {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-top: 1px solid var(--lightgrey);
  padding-top: 30px;
  align-items: flex-end;
}

.footer_bottom-item.devilopers {
  display: inline-flex;
  align-items: flex-end;
  gap: 15px;
}

.footer_bottom-item {
  text-decoration: none;
  color: var(--lightgrey);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  /* 178.571% */
}
.footer_bottom-item:nth-child(2) {
  text-align: center;
}
.footer_bottom-item:nth-child(3) {
  justify-content: flex-end;
}

@media screen and (max-width: 1200px) {
  .footer_up {
    flex-direction: column;
    gap: 50px;
  }
  .footer-menu {
    flex-direction: column;
    gap: 40px;
  }
  .footer_bottom {
    grid-template-columns: 1fr;
    gap: 20px;
    align-items: flex-start;
  }
  .footer_bottom-item:nth-child(2) {
    text-align: left;
  }
  .footer_bottom-item:nth-child(3) {
    justify-content: start;
  }
}
</style>
