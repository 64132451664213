<template>
  <div class="number-block ind" v-if="stage">
    <div class="number-block-title ind">{{ title }}</div>
    <div class="number-block-text" v-html="text"></div>
    <div class="number-block-number ind" v-show="pc">
      {{ ("Этап " + (index + 1)).toString().padStart(1, 0) }}
    </div>
    <div class="number-block-number ind mob" v-show="mobile">
      {{ (index + 1).toString().padStart(2, 0) }}
    </div>
  </div>
  <div class="number-block" v-else>
    <div class="number-block-title" v-if="title">{{ title }}</div>
    <div class="number-block-text" v-html="text"></div>
    <div class="number-block-number">
      {{ (index + 1).toString().padStart(2, 0) }}
    </div>
  </div>
</template>
<script>
export default {
  name: "NumberBlock",
  props: {
    title: "",
    text: "",
    index: 0,
    stage: false,
  },
  data() {
    return {
      pc: true,
      mobile: false,
    };
  },
  methods: {
    change() {
      if (window.innerWidth > 1200) {
        this.pc = true;
        this.mobile = false;
      } else {
        this.pc = false;
        this.mobile = true;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.change);
  },
  destroyed() {
    window.removeEventListener("resize", this.change);
  },
  mounted() {
    if (window.innerWidth < 1200) {
      this.pc = false;
      this.mobile = true;
    }
    this.change;
  },
};
</script>
<style>
.number-block {
  width: 100%;
  /* min-height: 180px; */
  position: relative;
}

.number-block:not(:last-of-type) {
  border-bottom: 1px solid var(--lightgrey);
  padding-bottom: 30px;
}

.number-block:not(:first-of-type) {
  padding-top: 30px;
}

.number-block-title.ind {
  color: var(--main-color);
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.number-block-title {
  color: var(--main-color);
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
}

.number-block-text {
  color: var(--main-color);
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  /* 125% */
}

.number-block-text ul li,
.number-block-text ol li {
  list-style: none;
}
.number-block-text ul li:before,
.number-block-text ol li:before {
  content: "·";
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 10px;
}
.number-block:not(:first-of-type) .number-block-number {
  top: calc(30px + 3px);
}

.number-block.ind .number-block-number {
  top: 5px;
}

.number-block.ind:not(:first-of-type) .number-block-number {
  top: 35px;
}

.number-block-number {
  position: absolute;
  color: var(--golden);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  top: 3px;
  width: 22px;
  left: calc(-22px - 15px);
}

.number-block-number.ind {
  position: absolute;
  color: var(--golden);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  top: 0;
  width: 62px;
  left: calc(-62px - 25px);
}

.number-block-number.ind.mob {
  position: absolute;
  color: var(--golden);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  top: 0;
  width: max-content;
  left: -40px;
}

@media screen and (max-width: 1200px) {
  .number-block-number {
    top: 0;
  }
  .number-block-text {
    font-size: 18px;
  }
  .number-block {
    min-height: auto;
  }
}
</style>
