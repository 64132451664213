<template>
  <div class="slider__wrapper">
    <div class="slider-btns">
      <div :class="'button-prev ' + swiper_id">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
        >
          <rect x="0.5" y="0.5" width="45" height="45" stroke="var(--golden)" />
          <path
            d="M18.9512 33.1167L8.83451 23L18.9512 12.8834"
            stroke="var(--golden)"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37.166 23.0005L9.11602 23.0005"
            stroke="var(--golden)"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div :class="'button-next ' + swiper_id">
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="45.5"
            y="45.5"
            width="45"
            height="45"
            transform="rotate(-180 45.5 45.5)"
            stroke="#CD9C1C"
          />
          <path
            d="M27.0488 12.8835L37.1655 23.0002L27.0488 33.1169"
            stroke="#CD9C1C"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.83398 23L36.884 23"
            stroke="#CD9C1C"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="slider">
      <swiper-container :id="swiper_id" init="false">
        <swiper-slide v-for="n in partners">
          <div class="slide-block">
            <div class="slide-block-img">
              <img :src="n.img" alt="" />
            </div>
            <div class="slide-block-name">{{ n.name }}</div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>
<script>
import { register } from "swiper/element/bundle";
import Swiper from "swiper";
export default {
  data() {
    return {
      slidesNumber: 2,
    };
  },
  props: {
    swiper_id: {
      type: String,
      required: true,
    },
    slidesPerView: {
      type: Number,
      default: () => 1,
      required: true,
    },
    partners: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    register();
    const swiperEl = document.querySelector("#" + this.swiper_id);
    const wrapper = swiperEl.shadowRoot.childNodes;
    const swiperParams = {
      slidesPerView: 2,
      spaceBetween: 80,
      grid: {
        rows: 2,
        fill: "row",
      },
      navigation: {
        nextEl: ".button-next." + this.swiper_id,
        prevEl: ".button-prev." + this.swiper_id,
      },
      clikable: true,
      breakpoints: {
        1200: {
          slidesPerView: this.slidesPerView,
        },
        100: {
          slidesPerView: 1.2,
          spaceBetween: 20,
          grid: {
            rows: 1,
          },
        },
      },
    };

    if (this.swiper_id == "partners") {
      swiperParams.injectStyles = [
        `
          :host .swiper-grid-column > .swiper-wrapper {
            flex-flow: row wrap;
          }
				`,
      ];
      swiperParams.on = {
        init(swiper) {
          swiper.slides.forEach((slide) => {
            slide.addEventListener("click", () => {
              swiper.slideTo(swiper.clickedIndex, 600);
            });
          });
        },
      };
    }

    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  },
};
</script>

<style scoped>
.reviews-slide {
  width: 100%;
  height: auto;
  background: #fff;
  box-shadow: 4px 5px 8px 5px rgba(88, 88, 88, 0.08);
  margin: 5px -4px 0 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 87px;
}

.reviews-slide p {
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.5%;
  /* 23.13px */
}

.reviews-user {
  display: flex;
  flex-direction: row;
  gap: 34px;
}

.reviews-slide .user-name {
  color: #343434;
  font-family: Mont;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 124.5%;
}

.reviews-slide .user-solution {
  color: #585858;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.5%;
}

.reviews-slide img {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
}
.slider {
  width: 100%;
}

swiper-container {
  display: block;
  position: relative;
}

#team .swiper {
  overflow: unset !important;
}

.slider__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.slider-btns {
  display: flex;
  gap: 20px;
  align-self: flex-end;
}

.button-prev,
.button-next {
  position: relative;
  min-width: 46px;
  max-height: 46px;
  cursor: pointer;
}

.button-prev svg > *,
.button-next svg > * {
  transition: 0.5s;
}

.button-prev:hover svg rect,
.button-next:hover svg rect {
  fill: var(--golden);
}

.button-prev:hover svg path,
.button-next:hover svg path {
  stroke: var(--white);
}

.swiper-button-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.slider-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: 0.5s;
}

.swiper-pagination-bullet {
  border-radius: 50%;
  background-color: darkgray;
  height: 10px;
  width: 10px;
  cursor: pointer;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: black;
}

.swiper-button-lock {
  opacity: 0;
}
@media screen and (max-width: 1200px) {
  .slider__wrapper {
    max-width: none;
  }
  .reviews-slide {
    background: #fff;
    box-shadow: 4px 5px 8px 5px rgba(88, 88, 88, 0.08);
    gap: 60px;
  }
  .reviews-user {
    gap: 27px;
  }
  .reviews-slide .user-name {
    font-size: 20px;
    font-weight: 600;
  }
  .user-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .reviews-slide .user-solution {
    font-size: 16px;
    font-weight: 600;
  }
  .reviews-slide p {
    font-size: 16px;
  }
}
</style>
