<template>
  <SectionTitleRow
    :title="individual_projects?.block_title"
    v-if="individual_projects?.projects.length > 0"
  >
    <div class="projects-content-grid">
      <div class="project1 project" v-if="individual_projects?.projects[0]">
        <img :src="individual_projects?.projects[0].img" alt="" />
        <div class="project-desc">
          <div class="title">
            <span>{{ individual_projects?.projects[0].title }}</span>
            {{ individual_projects?.projects[0].text }}
          </div>
        </div>
      </div>
      <div class="project2 project" v-if="individual_projects?.projects[1]">
        <img :src="individual_projects?.projects[1].img" alt="" />
        <div class="project-desc">
          <div class="title">
            <span>{{ individual_projects?.projects[1].title }}</span>
            {{ individual_projects?.projects[1].text }}
          </div>
        </div>
      </div>
      <div class="project3 project" v-if="individual_projects?.projects[2]">
        <img :src="individual_projects?.projects[2].img" alt="" />
        <div class="project-desc">
          <div class="title">
            <span>{{ individual_projects?.projects[2].title }}</span>
            {{ individual_projects?.projects[2].text }}
          </div>
        </div>
      </div>
    </div>
  </SectionTitleRow>
</template>
<script>
import SectionTitleRow from "../UI/SectionTitleRow.vue";
export default {
  name: "IndividualProjectImg",
  components: { SectionTitleRow },
  props: {
    individual_projects: {
      block_title: "",
      projects: [],
    },
  },
};
</script>
<style scoped>
.project.project1 img {
  max-height: 600px;
  height: 100%;
}
.project.project2 img {
  max-height: 280px;
  height: 100%;
}
.project3 img {
  max-width: none;
}
.project.project3 img {
  max-height: 280px;
  height: 100%;
}
.left-column.w65 {
  gap: 0;
}
.project.project3 {
  max-width: 380px;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-self: end;
  margin-top: calc(-30px - 45px);
  height: auto;
}
.project .project-desc {
  width: 100%;
}

.project1.project .title {
  width: 56%;
}

@media screen and (max-width: 1200px) {
  .project .project-desc {
    width: 100%;
  }
  .left-column.w65 {
    gap: 50px;
  }
  .project.project3 {
    margin-top: 0;
  }
}
</style>
