<template>
    <div class="slider__wrapper">
        <div class="slider-btns">
            <div :class="'button-prev ' + swiper_id">
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                    <rect x="0.5" y="0.5" width="45" height="45" stroke="var(--golden)" />
                    <path d="M18.9512 33.1167L8.83451 23L18.9512 12.8834" stroke="var(--golden)" stroke-width="2"
                        stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M37.166 23.0005L9.11602 23.0005" stroke="var(--golden)" stroke-width="2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <div :class="'button-next ' + swiper_id">
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="45.5" y="45.5" width="45" height="45" transform="rotate(-180 45.5 45.5)" stroke="#CD9C1C" />
                    <path d="M27.0488 12.8835L37.1655 23.0002L27.0488 33.1169" stroke="#CD9C1C" stroke-width="2"
                        stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.83398 23L36.884 23" stroke="#CD9C1C" stroke-width="2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>
        <div class="slider">
            <swiper-container :id='swiper_id' init="false">
                <swiper-slide v-for="n in 8">

                    <slot></slot>
                </swiper-slide>
            </swiper-container>
        </div>
        <!-- <div class="slider-pagination"></div> -->
    </div>
</template>

<style scoped>
.slider {
    /* max-width: calc(100% - 200px); */
    width: 100%;
}

swiper-container {
    display: block;
    position: relative;
}

#team .swiper {
    overflow: unset !important;
}

.slider__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: calc(100% - 460px);
    width: 100%;
}

.slider-btns {
    display: flex;
    gap: 20px;
    align-self: flex-end;
}

.button-prev,
.button-next {
    position: relative;
    min-width: 46px;
    max-height: 46px;
    cursor: pointer;
}

.button-prev svg>*,
.button-next svg>* {
    transition: .5s;
}

.button-prev:hover svg rect,
.button-next:hover svg rect {
    fill: var(--golden);
}

.button-prev:hover svg path,
.button-next:hover svg path {
    stroke: var(--white);
}

.swiper-button-disabled {
    opacity: 0.4;
    pointer-events: none;
}

swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: 0.5s;
}

.swiper-pagination-bullet {
    border-radius: 50%;
    background-color: darkgray;
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: black;
}
</style>

<script>
import { register } from 'swiper/element/bundle';

export default {
    data() {
        return {
            slidesNumber: 2
        }
    },
    props: {
        swiper_id: {
            type: String,
            required: true
        },
        slidesPerView: {
            type: Number,
            default: () => 1,
            required: true
        }
    },
    mounted() {
        register();
        const swiperEl = document.querySelector('#' + this.swiper_id);
        const swiperParams = {
            slidesPerView: 1,
            spaceBetween: 30,
            navigation: {
                nextEl: '.button-next.' + this.swiper_id,
                prevEl: '.button-prev.' + this.swiper_id,
            },
            pagination: {
                el: '.slider-pagination',
                clickable: true,
            },
            breakpoints: {
                1200: {
                    slidesPerView: this.slidesPerView,
                },
            },
        };

        if (this.swiper_id == 'team'){
            swiperParams.injectStyles = [
                `
                :host .swiper {overflow: unset;}
                `
            ];
            swiperParams.spaceBetween = 50;
            swiperParams.on = {
                init(swiper) {
                    swiper.slides.forEach(slide => {
                        slide.addEventListener('click', ()=> {
                            swiper.slideTo(swiper.clickedIndex, 600)
                        })
                    });
                }
            }
        }

        Object.assign(swiperEl, swiperParams);
        swiperEl.initialize();
    }
}
</script>