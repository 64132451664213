<template>
  <div class="number-drop-container">
    <div class="number-drop-number">
      {{ (index + 1).toString().padStart(2, 0) }}
    </div>
    <div class="number-drop-header" @click="openDropItem()">
      <p ref="header" class="mob-text">{{ header }}</p>
      <div class="number-drop-btn" ref="btn">
        <span class="btn-line"></span>
        <span class="btn-line second"></span>
      </div>
    </div>
    <div class="number-drop-item" ref="drop_item">
      <slot> </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    is_toggle_drop: false,
    header: "",
    text: "",
    index: "",
  },
  data() {
    return {
      is_drop: false,
    };
  },
  methods: {
    openDropItem() {
      const drop_item = this.$refs.drop_item,
        drop_height = drop_item.scrollHeight,
        drop_btn = this.$refs.btn,
        list = drop_item.closest(".drop-list");
      if (drop_item.style.maxHeight == "") {
        list.querySelectorAll(".number-drop-container").forEach((el) => {
          if (el.querySelector(".number-drop-btn")) {
            el.querySelector(".number-drop-btn").classList.remove("active");
            // this.$refs.header.classList.remove("active");
          }
          el.querySelector(".number-drop-item").style.maxHeight = "";
          //  this.$refs.header.classList.remove("active");
        });
        drop_item.style.maxHeight = drop_height + "px";
        //   this.$refs.header.classList.add("active");
        if (drop_btn) {
          drop_btn.classList.add("active");
          //  this.$refs.header.classList.add("active");
        }
      } else {
        drop_item.style.maxHeight = "";
        if (drop_btn) {
          drop_btn.classList.remove("active");
          //  this.$refs.header.classList.add("active");
        }
      }
    },
  },
};
</script>
<style>
.number-drop-container {
  width: 100%;
  position: relative;
}

.number-drop-container:not(:first-of-type) {
  margin-top: 30px;
}

.number-drop-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--white);
}

.number-drop-btn {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--golden);
}

.number-drop-btn.active .btn-line {
  /* transform: rotate(-45deg) translate(-2px, -3px); */
  transform: rotate(-45deg);
}

.number-drop-btn.active .btn-line.second {
  /* transform: rotate(45deg) translate(2px, -3px); */
  transform: rotate(45deg);
}

.btn-line {
  transition: 0.4s;
  position: absolute;
  top: 50%;
  /* left: 50%; */
  width: 10px;
  transform: rotate(45deg) translate(-2px, 3px);
  border-top: 1px solid var(--golden);
}

.btn-line.second {
  transform: rotate(-45deg) translate(2px, 3px);
}

.number-drop-item {
  max-height: 0px;
  overflow: hidden;
  transition: 0.8s;
}

.number-drop-header p {
  color: var(--main-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 30px; /* 125% */
}
/* transition: 0.5s;
}
@media screen and (max-width: 1200px) {
  .number-drop-header p.mob {
  }
  .number-drop-header p.active {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
} */

.number-drop-number {
  position: absolute;
  color: var(--golden);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  top: 0;
  width: 22px;
  left: calc(-22px - 15px);
}

@media screen and (max-width: 1200px) {
  .number-drop-container:not(:first-of-type) {
    margin-top: 20px;
  }
  .number-drop-header {
    align-items: flex-start;
  }
  /* .number-drop-header .number-drop-btn {
    margin-top: 5px;
  } */
}
</style>
