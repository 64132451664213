<template>
  <div class="socilaObjects-view">
    <MainSocialObject :title="page_data.title" />
    <!-- <SectionOrder
      :title="'Заявка на проект'"
      :form_title="'Заявка на строительство вашего идеального дома'"
    /> -->
  </div>
</template>
<script>
import MainSocialObject from "@/components/social-objects/MainSocialObject.vue";
// import SectionOrder from "@/components/UI/SectionOrder.vue";

import { mapState, mapActions } from "vuex";
export default {
  name: "SocialObjectsView",
  components: {
    MainSocialObject,
    // SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/social-objects");
  },
};
</script>
<style></style>
