<template>
  <TitleWithColumns>
    <template v-slot:title>
      <h1>{{ title }}</h1>
    </template>
    <div class="twc-list" :class="btn ? 'with-btn' : ''" v-if="btn">
      <div class="twc-list-text">{{ text }}</div>
      <Button :color="'white'">Рассчитать</Button>
    </div>
    <div class="twc-list" :class="pic ? 'with-pic' : ''" v-if="pic">
      <div class="twc-list-text">{{ text }}</div>
      <div class="twc-list-img">
        <img :src="require('@/assets/img/stocks.jpg')" alt="" />
      </div>
    </div>
    <div class="twc-list" v-if="!btn && !pic">
      <div class=""></div>
      <div class="twc-list-text" v-for="item in texts">{{ item.text }}</div>
    </div>
  </TitleWithColumns>
</template>
<script>
import TitleWithColumns from "@/components/UI/TitleWithColumns.vue";
import Button from "@/components/UI/Button.vue";

export default {
  name: "MainMortgage",
  components: {
    TitleWithColumns,
    Button,
  },
  props: {
    title: "",
    texts: [],
  },
};
</script>

<style>
.twc-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  justify-content: space-between;
}

.twc-list.with-btn .twc-list-text {
  grid-column: 2;
}

.twc-list.with-btn .button-link {
  align-self: center;
}

.twc-list-text {
  color: var(--grey);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.twc-list-img {
  grid-area: 1 / 2 / 2 / 4;
  grid-row: 1 / 2;
}
</style>
